<template>
  <div class="total">
    <div class="head">
      <div class="top-item" @click="goBack" style="cursor: pointer">
        <i class="el-icon-arrow-left"></i>
        <p>返回上级</p>
      </div>
      <div class="top-item" style="margin-left: 10vw">
        <p>检测结果</p>
<!--        <i class="el-icon-arrow-down"></i>-->
      </div>
      <div class="top-item" style="justify-content: end;position: absolute;right: 2%">
        <!--        <img class="img2" src="../../assets/load.png" alt="">-->
<!--        <p @click="goEdit" class="editBtn">在线编辑</p>-->
      </div>
    </div>
    <div class="tip_box" style="padding-left: 2%">
<!--      <div class="tip-item1">-->
<!--        <img class="img1" src="../../assets/old.png" alt="">-->
<!--        <p>原文</p>-->
<!--      </div>-->
      <div class="tip-item2" style="justify-content: center">
<!--        <div class="color-box blue"></div>-->
        <img class="color-box" src="../../assets/henji.png" alt="">
        <p style="margin-right: 25px">AI痕迹</p>
<!--        <div class="color-box red"></div>-->
        <img class="color-box" src="../../assets/chahchon.png" alt="">
        <p>查重</p>
      </div>
<!--      <div class="tip-item3">-->
<!--        <p>查重相似来源区(点击左侧识别处可查看)</p>-->
<!--      </div>-->
    </div>
    <div style="display: flex">
<!--      -->
      <div class="detail1" @scroll="scroll">
<!--        <div class="detail_item" style="margin-right: 0.35%;">-->
<!--          <div v-html="test1"></div>-->
<!--        </div>-->
        <div class="detail_item">
          <div id="test2"></div>
        </div>
      </div>

    </div>
    <p class="credits_tip" style="position: absolute;">原文统计:{{credits}}字</p>
    <div class="bigbox" v-if="web_check_now">
      <div class="bigbox_no" @click="web_check_now=null"></div>
      <div class="detail2" @scroll="scroll">
        <div style="display: flex;width: 100vw;height: 40px;padding-left: 40vw;position: relative">
          问题语句
          <img @click="web_check_now=null" src="../../assets/num_del.png" alt="" style="position: absolute;height: 30px;width: 30px;left: 80vw;top: -5px">
        </div>
        <div class="box">
          问题语句
        </div>
        <p class="questions_p">{{content}}</p>
        <div class="box">
          共识别出{{web_check_now.length}}处痕迹
        </div>
        <div class="questions_box" v-for="(item,index) in web_check_now">
          <div class="questions_box_1">
            <div :class="[ index%2===0?'questions_box_1_index1': 'questions_box_1_index1 questions_box_1_index2' ]">
              {{index+1}}
            </div>
            <div :class="[ index%2===0?'questions_box_1_title1': 'questions_box_1_title1 questions_box_1_title2' ]">
              重复{{item.score * 100|zeroDecimals}}%
            </div>
          </div>
          <div class="questions_box_2">
            <p style="line-height: 20px;margin-top: 15px">{{ item.title }}</p>
            <p v-html="item.desc"></p>
          </div>
          <div class="questions_box_1" v-if="item.link">
            <img style="height: 20px;width: 20px" src="../../assets/link_p.png" alt="">
            <p class="questions_box_1_title3">来源链接：</p>
          </div>
          <div class="questions_box_1" v-if="item.link">
            <a class="questions_box_1_a" target="_blank" :href="item.link">{{item.link}}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {analyzeDetail} from "@/api/1";

export default {
  data() {
    return {
      description:"暂无相似来源",
      content:"",
      questions_list:[],
      id:'',
      credits:0,
      filename:'',
      test1:'<pre style="color:Green; font-weight:bold;">测试字体</pre>',
      test2:'<pre style="color:Green; font-weight:bold;">测试字体</pre>',
      web_check:[],
      web_check_data:[],
      web_check_now: ''
    };
  },
  mounted() {
    let self = this;
    //模板参数传参
    const _this = this
    window.previewNvrVideo = function (j) {
      _this.console_test(j);
    }
    window.hoverStyle = function (j) {
      _this.hoverStyle(j);
    }
    window.outStyle = function (j) {
      _this.outStyle(j);
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.credits = this.$route.query.credits;
    this.filename = this.$route.query.filename;
    // console.log(this.filename)
    this.getDetail()
  },
  methods: {
    getDetail(){
      analyzeDetail(this.id).then(res => {
        // console.log(res.data.data.ai_check)
        const bookContent = res.data.data.content
        this.test1='<pre style="font-size: 15px;line-height: 20px;white-space: pre-wrap;word-wrap: break-word;overflow-wrap: break-word;width: 100%">'+res.data.data.content+'</pre>'
        this.test2='<pre style="font-size: 15px;line-height: 20px;white-space: pre-wrap;word-wrap: break-word;overflow-wrap: break-word;width: 100%;">'+res.data.data.content+'</pre>'
        for (let i = 0; i < res.data.data.ai_check.length; i++) {
          // console.log(res.data.data.ai_check[i][2])

          this.test2 = this.test2.replace(res.data.data.ai_check[i][2],
              '<span style="color:#6396fd">'+res.data.data.ai_check[i][2]+'</span>');
        }
        this.web_check=res.data.data.web_check
        this.web_check_data=res.data.data.web_check_data
        for (let j = 0; j < res.data.data.web_check.length; j++) {
          if(res.data.data.web_check[j][0]!==-1){
            let book_web = bookContent.slice(res.data.data.web_check[j][0], res.data.data.web_check[j][1])
            const name = "id='id"+j+"'"
            this.test2 = this.test2.replace(book_web,
                '<span onmouseenter="hoverStyle('+j+')" onmouseleave="outStyle('+j+')"'+name+ 'onclick="previewNvrVideo('+j+')" ' +
                'style="cursor: pointer;background-color: #fdfd83">'+book_web+'</span>');
          }else {
            // console.log(res.data.data.web_check[j][2])
            const name = "id='id"+j+"'"
            this.test2 = this.test2.replace(res.data.data.web_check[j][2],
                '<span onmouseenter="hoverStyle('+j+')" onmouseleave="outStyle('+j+')"'+name+ 'onclick="previewNvrVideo('+j+')" ' +
                'style="cursor: pointer;background-color: #fdfd83">'+res.data.data.web_check[j][2]+'</span>');          }
        }
        document.getElementById('test2').innerHTML = this.test2
      });
    },
    console_test(j){
      // console.log(this.web_check[j][3].length)
      // if(Array.isArray(this.web_check[j])){
        this.content=this.web_check[j][2]
        for (let i = 0; i < this.web_check[j][3].length; i++) {
          this.web_check[j][3][i].desc = this.web_check[j][3][i].desc.replaceAll('<em>','<span style="color:#6396fd;font-size: 15px;">')
          this.web_check[j][3][i].desc = this.web_check[j][3][i].desc.replaceAll('</em>','</span>')
        }
        // console.log(this.web_check[j][3])
        this.web_check_now=this.web_check[j][3]
      // }else {
      //   for (let i = 0; i < this.web_check[j].web_check.length; i++) {
      //     this.web_check[j].web_check[i].desc = this.web_check[j].web_check[i].desc.replaceAll('<em>','<span style="color:#6396fd;font-size: 15px;">')
      //     this.web_check[j].web_check[i].desc = this.web_check[j].web_check[i].desc.replaceAll('</em>','</span>')
      //   }
      //   this.content=this.web_check[j].content
      //   this.web_check_now=this.web_check[j].web_check
      // }
    },
    goBack(){
      this.$router.push("/category")
    },
    goEdit(){
      this.$router.push({
        path: '/firstpage',
        query: { id: this.id,filename: this.filename }
      });
    },
    hoverStyle(s){
      // console.log("我有进来的~~~~~~~",s)
      document.getElementById("id"+s).style.backgroundColor = "#fdc083";
    },
    outStyle(s){
      // console.log("我有进来的~~~~~~~",s)
      document.getElementById("id"+s).style.backgroundColor = "#fdfd83";
    },
    scroll(e){
      // console.log(e)
    }
  },
};
</script>





<style scoped>
.bigbox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000000;
  height: 100vh;
}
.bigbox_no{
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  height: 55vh;
  background-color: rgba(96, 84, 84, 0.1);
}

.questions_box{
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  height: auto;
  margin-top: 20px;
  background-color: #f3f7fa;
}
.questions_box_2 p{
  font-size: 15px;
  line-height: 20px;
}
.questions_box_1{
  display: flex;
}
.questions_box_1_index1{
  width: 22px;
  color: white;
  text-align: center;
  font-size: 13px;
  height: 18px;
  background: #6598FF;
  border-radius: 12px 12px 12px 12px;
  margin-right: 10px;
}
.questions_box_1_index2{
  background: #21a9e3;
}
.questions_box_1_title1{
  line-height: 18px;
  font-size: 15px;
  color: #6598FF;
}.questions_box_1_title3{
  line-height: 22px;
  margin-top: 0;
  font-size: 15px;
  color: #6598FF;
}.questions_box_1_title2{
  color: #21a9e3;
}
 .questions_box_1_a{
   word-wrap: break-word;
   color: black;
   margin-top: 0;
   line-height: 20px;
   width: 100%;
   position: relative;
   bottom: 10px;
 }
.box {
  position: relative;
  padding-left: 15px; /* 留出小方块的空间 */
}

.questions_p{
  color: #6497fe;
}

.box::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%; /* 方块垂直居中 */
  transform: translateY(-50%);
  width: 8px; /* 小方块的宽度 */
  height: 12px; /* 小方块的高度 */
  background-color: #6497fe
}
.credits_tip{
  bottom: -5px;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: darkgrey;
}
.tip_box{
  height: 50px;
  display: flex;
}
.total{
  position: relative;
  height: 100vh;
  width: 100vw;
  min-height: 600px;
  background-color: #f9f9fa;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  /*scrollbar-width: none;*/
  height: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}

.detail1{
  /*display: flex;*/
  /*position: relative;*/
  margin-left: 5vw;
  width: 90vw;
  /*min-width: 1000px;*/
  height: 83vh;
  box-shadow: 1px 1px 10px 5px rgba(213,213,213,0.3);
  border-radius: 0 0 0 0;
  border: 1px solid #D1E0FF;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  float: left;
  /*flex-shrink: 0;*/
}
.detail1big{
  width: 96%;
}
.detail2{
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  margin-left: 0.2%;
  width: 100vw;
  height: 45vh;
  box-shadow: 1px 1px 10px 5px rgba(213,213,213,0.3);
  border-radius: 0 0 0 0;
  border: 1px solid #D1E0FF;
  background-color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  float: left;
  /*flex-shrink: 0;*/
}

.detail_item{
  padding: 20px;
  box-sizing: border-box;
  width: 99%;
  border: 1px solid #D1E0FF;
  float: left;
  /*flex-shrink: 0;*/
  height: 83vh;
  background: #FFFFFF;
  overflow-x: scroll;
  box-shadow: -10px 0 10px -10px rgba(18, 21, 38, 0.3);
}
.top-item{
  display: flex;
  width: 33vw;
  font-size: 14px;
  margin-left: 10px;
  line-height: 25px;
  /*justify-content: space-around;*/
}
.tip-item1{
  display: flex;
  width: 28.5%;
  font-size: 15px;
  margin-left: 10px;
  line-height: 23px;
  /*justify-content: space-around;*/
}.tip-item2{
  display: flex;
  width:50vw;
  margin-left: 25vw;
  font-size: 15px;
  line-height: 23px;
  /*justify-content: space-around;*/
}.tip-item3{
  display: flex;
  /*width: 32%;*/
  font-size: 15px;
  margin-left: 10px;
  line-height: 23px;
  /*justify-content: space-around;*/
}
.el-icon-arrow-left{
  margin-top: 12px;
  font-size: 26px;
  margin-right: 5px;
}
.el-icon-arrow-down{
  margin-top: 12px;
  font-size: 26px;
  margin-left: 5px;
}
.head{
  box-sizing: border-box;
  position: relative;
  /*justify-content: space-between;*/
  height: 50px;
  display: flex;
  width: 100%;
  background: #FBFBFB;
  /*padding-left: 15vw;*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 水平偏移 垂直偏移 模糊半径 颜色 */
}
.editBtn{
  background-color: #6497fe;
  color: white;
  height: 30px;
  margin-top: 11px;
  width: 80px;
  text-align: center;
  line-height: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.color-box{
  height: 18px;
  width: 20px;
  margin-top: 17px;
  margin-right: 5px;
}
.blue{
  background-color: #6497fe;
}
.red{
  background-color: #f75e5d;
}
.img1{
  height: 15px;
  width: 20px;
  margin-top: 19px;
  margin-right: 5px;
}

.img2{
  height: 25px;
  width: 25px;
  margin-top: 16px;
  margin-right: 20px;
}
</style>