<template>
  <div
      style="background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);position: relative;width: 100vw;height: 100vh">
    <div class="main">
      <div class="container">
        <div style="display: flex;float: left;padding: 18px;height: 30px">
          <img style="width: 20px;height: 20px;margin-top: 5px" src="../../assets/alert.png" alt="">
          <p style="line-height: 0px;margin-left: 10px;font-size: 15px;color: #4D71FF">
            请稍作等待，检测将在2-5分钟后出结果</p>
        </div>
        <div class="custom-scrollbar" @scroll="handleScroll"
             style="height: 65vh;overflow-y: scroll;width: 100%;overflow-x: scroll">
          <el-empty style="margin-top: 8vh" :description="description" v-if="booklist.length==0"></el-empty>
          <div v-for="(item,index) in booklist" class="book_item">
            <div style="display: flex">
              <div class="slh" style="width: 38%;color: #4D71FF">{{ item.filename }}</div>
              <div class="slh slh_small" style="width: 20%">字数:{{ item.credits }}</div>
              <div class="slh slh_small" style="width: 42% ">{{ item.createtime | formatDate }}</div>
            </div>
            <div style="display: flex">
              <div class="slh" style="width: 38%;color: #4D71FF" v-if="item.web_check===0"><span
                  style="color: black;margin-right: 5px">查重率:</span>检测中
              </div>
              <div class="slh" style="width: 38%;color: red" v-if="item.web_check===2"><span
                  style="color: black;margin-right: 5px">查重率:</span>检测失败
              </div>
              <div class="slh" style="width: 38%;color: red" v-if="item.web_check===-1"><span
                  style="color: black;margin-right: 5px">查重率:</span>检测失败
              </div>
              <div class="slh" style="width: 38%;color: #1D9461" v-if="item.web_check===1">
                <span style="color: black;margin-right: 5px">查重率:</span>{{
                  item.web_check_count === 0 ? "0" : item.web_check_count / item.credits * 100 | twoDecimals
                }}%
              </div>
              <div class="slh" style="width: 38%;color: #4D71FF" v-if="item.status===0"><span
                  style="color: black;margin-right: 5px">AI痕迹:</span>检测中
              </div>
              <div class="slh" style="width: 38%;color: red" v-if="item.status===2"><span
                  style="color: black;margin-right: 5px">AI痕迹:</span>检测失败
              </div>
              <div class="slh" style="width: 38%;color: red" v-if="item.status===-1"><span
                  style="color: black;margin-right: 5px">AI痕迹:</span>检测失败
              </div>
              <div class="slh" style="width: 38%;color: #1D9461" v-if="item.status===1">
                <span style="color: black;margin-right: 5px">AI痕迹:</span>{{
                  item.ai_check_count === 0 ? "0" : item.ai_check_count / item.credits * 100 | twoDecimals
                }}%
              </div>
              <div class="slh" style="width: 24%;">
                <div class="look" @click="lookDetail(item.id,item.credits,item.filename)"
                     v-if="item.status===1||item.web_check===1">
                  查看
                </div>
                <div style="background-color: darkgrey" class="look" v-else>
                  查看
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="alert_tip">
      书镜鉴文所标记的AI痕迹字句，皆由书镜鉴文根据AI常用字句自行甄别得出，无论任何稿子都有一定可能被标出AI痕迹，因此书镜鉴文所标记的AI痕迹字句，只提供参考修改作用，无法用于确切证明文稿是由AI创作得出。</p>
    <TelFeet :click="2"></TelFeet>
  </div>
</template>

<script>
import top from "@/components/Top.vue";
import Login from "@/components/Login.vue";
import TelFeet from "@/components/tel_feet.vue";
import {analyzelist} from "@/api/1";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {Login, top, TelFeet},
  data() {
    return {
      description: "检查生成报告中……",
      click: 2,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      booklist: []
    };
  },
  created() {
    // console.log(this.isMobile())
    if (this.isMobile()) {
      this.$router.push("/categoryTel")
    } else {
      this.$router.push("/category")
    }
    this.getList()
    setTimeout(() => {
      this.$refs.tops.reLoad()
    }, 1000);
  },

  methods: {
    // 滚动事件
    handleScroll(event) {
      const dialog = event.target;
      if (dialog.scrollHeight - dialog.scrollTop === dialog.clientHeight) {
        // 当内容滑动到底部时，执行想要的操作
        // console.log("到底啦~一点都没有了")
        if (this.total > this.currentPage * this.pageSize) {
          this.currentPage++
          this.getList_now()
        }
      }
    },
    isMobile() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    getList_now() {
      analyzelist({page: this.currentPage, limit: this.pageSize}).then(res => {
        // console.log(res)
        this.booklist = [...res.data.data, ...this.booklist]
        this.total = res.data.total
        this.description = "未生成检测报告"
      })
    },
    getList() {
      setTimeout(() => {
        if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
          analyzelist({page: this.currentPage, limit: this.pageSize}).then(res => {
            // console.log(res)
            this.booklist = [...res.data.data, ...this.booklist]
            this.total = res.data.total
            this.description = "未生成检测报告"
          }).catch(error => {
            this.$router.push({
              path: '/register'})
          });
        } else {
          this.$router.push({
            path: '/register'})
        }
      }, 1000);
    },
    lookDetail(id, credits, filename) {
      this.$router.push({
        path: '/incomeTel',
        query: {id: id, credits: credits, filename: filename}
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = Number(val)
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = Number(val)
      // console.log(`当前页: ${val}`);
      this.getList()
    }
  },
};
</script>

<style scoped>
.alert_tip {
  left: 5vw;
  width: 90vw;
  text-align: center;
  position: absolute;
  top: 78vh;
  font-size: 13px;
  color: darkgrey;
}

/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f1eded; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #b7b0b0 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}

.feet {
  display: flex;
  justify-content: space-between;
  height: 6vh;
  position: absolute;
  /*background-color: black;*/
  bottom: 0;
  width: 95%;
  margin-left: 2.5%;
}

.look {
  background: #6598FF;
  border-radius: 4px 4px 4px 4px;
  width: 80%;
  height: 28px;
  margin-top: 5px;
  line-height: 28px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.slh {
  padding-left: 10px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
}

.slh_small {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989899;
  font-size: 13px;
  box-sizing: border-box;
  white-space: nowrap;
}

.container {
  position: relative;
  width: 94vw;
  height: 76vh;
  margin-top: 1vh;
  margin-left: 3vw;
  border-radius: 12px 12px 12px 12px;
}

.book_item {
  line-height: 40px;
  box-shadow: 5px 5px 5px 0px rgba(234, 234, 234, 0.25);
  height: 80px;
  width: 100%;
  font-size: 15px;
  background-color: white;
  border-radius: 8px;
  margin-top: 10px
}

.main {
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}
</style>