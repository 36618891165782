<template>
  <div style="background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);min-height: 100vh;min-width: 100vw;padding-top: 3vh;box-sizing: border-box">
    <div class="main">
      <div class="current2">
        <div class="smallItem2" style="position: relative;width: 100%;margin: 0;background-color: white">
          <div class="custom-scrollbar" style="height: 85%;overflow-y: scroll;">
            <div style="height: 55px;background-color: #F1F6FF;width: 100%;display: flex;line-height: 55px">
              <div style="width: 30%">服务类型</div>
              <!--              <div style="width: 25%">用户ID</div>-->
              <div style="width: 40%">提交时间</div>
              <div style="width: 30%">消耗积分</div>
            </div>
            <div :key="index" v-for="(item,index) in booklist" style="height: 55px;width: 100%;display: flex;line-height: 55px;border-bottom: #E7E7E7 1px solid;font-size: 15px">
              <div class="slh" style="width: 30%;">AI检测</div>
              <!--              <div class="slh" style="width: 25%">{{ item.word_count }}</div>-->
              <div class="slh" style="width: 40%">{{ item.usage_time|formatDate }}</div>
              <div class="slh" style="width: 30%;color: #1D9461">{{ item.word_count }}</div>
            </div>
            <el-empty v-if="booklist.length===0" description="暂无消费记录" style="margin-top: 10vh"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  changeavatar, changenickname, changepwd, order_upgrade, payment_record, unbind, usermember_use
} from "@/api/1";
import QRCode from 'qrcodejs2';
import axios from "axios";

export default {
  // eslint-disable-next-line vue/no-unused-components
  data() {
    return {
      params: 1,
      booklist: [],
      usagePage:1,
    }
  },
  created() {
    // console.log(this.isMobile())
    if (this.isMobile()) {
      this.$router.push("/xfjl")
    } else {
      this.$router.push("/records")
    }
    const that = this
    setTimeout(() => {
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        that.get_payment_record()
      } else {
        that.$router.push("/")
      }
    }, 500);
  },

  methods: {
    //该方法用于判断是否进入手机端
    isMobile() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },

    dateChange(e) {
      // console.log(e)
      this.get_payment_record()
    },
    get_payment_record() {
      usermember_use({page: this.usagePage,limit: 1000}).then(res => {
        this.usageTotal=res.data.data.total
        this.booklist=res.data.data.rows
      });
    },
  },
};
</script>

<style scoped>
.slh{
  text-align: center;
}
/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}


.smallItem2 {
  margin-left: 7%;
  height: 87%;
  /*background-color: black;*/
}

.current2 {
  width: 90vw;
  min-height: 90vh;
  margin-top: 3vh;
  /*box-shadow: 1px 1px 10px 5px rgba(216, 227, 255, 0.3);*/
  border-radius: 12px 12px 12px 12px;
  /*border: 1px solid #D1E1FF;*/
}

.main {

  width: 90vw;
  margin-left: 5vw;
  background: white;
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
}
.slh{
  text-align: left;
  font-size: 14px;
}
.dateSelect {
  display: flex;
  height: 35px;
  position: relative;
}

.el-icon-date {
  font-size: 20px;
  color: gray;
  left: 20px;
  top: 20px;
  position: absolute;
}

.span {
  font-size: 15px;
  color: grey;
  left: 43px;
  top: 19px;
  position: absolute;
}

.el-select {
  position: absolute;
  left: 85px;
  top: 10px;
}
</style>