<template>
  <div style="background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);min-height: 100vh;min-width: 1200px;">
    <Change_tel ref="changeTel"></Change_tel>
    <Change_we ref="changeWe"></Change_we>
    <top ref="tops" :click="click"></top>
    <div class="main">
      <div class="tabList">
        <div v-for="(item,index) in tabList">
          <div class="tabItem clickBtn" v-if="index===current" @click="currentChange(index)">
            {{ item.name }}
          </div>
          <div class="tabItem" @click="currentChange(index)" v-else>
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="current1" v-if="current===0">
        <div class="smallItem">
          <div class="smallItem_1">
            <img class="smallItem_1_img1" src="../../assets/final_.png" alt="">
            <p class="smallItem_1_p1">企业级客户定制</p>
            <p class="smallItem_1_p2">若当前会员等级，无法满足您的日常消耗量，请联系商务购买专属账号，获取更多超值优惠。</p>
            <img class="smallItem_1_img2" src="../../assets/ewm2.png" alt="">
            <p class="smallItem_1_p3">打开微信扫一扫</p>
            <p class="smallItem_1_p4">联系平台客户经理</p>
          </div>
          <div class="smallItem_left">
            <div class="trapezoid" v-if="trapezoid===0||trapezoid===1">
              <div class="trapezoid-right" @click="trapezoidChange(1)" v-if="trapezoid===0">购买加量包</div>
              <div class="trapezoid-right2" @click="trapezoidChange(1)" v-else>购买加量包</div>
              <div class="trapezoid-left" @click="trapezoidChange(0)" v-if="trapezoid===0"><p
                  style="position: relative;bottom: 50px">会员充值</p></div>
              <div class="trapezoid-left2" @click="trapezoidChange(0)" v-else><p style="position: relative;bottom: 50px">
                会员充值</p></div>
            </div>
            <div v-if="trapezoid===0">
              <div class="flex" style="justify-content: space-between;width: 89%">
                <p style="color:#c1131f;">当前会员：
                  <span v-if="now_vip_status.top_level===-1||now_vip_status.top_level===0">未开通</span>
                  <span v-if="now_vip_status.top_level===1">初级</span>
                  <span v-if="now_vip_status.top_level===2">中级</span>
                  <span v-if="now_vip_status.top_level===3">高级</span>
                  <span v-if="now_vip_status.top_level===4">旗舰版</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='month'">-月卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='quarter'">-季卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='year'">-年卡</span>
                </p>
                <!--                v-if="now_vip_status.top_level!==-1"-->
                <p @click="trapezoidChange(2)" v-if="now_vip_status.top_level!==-1&&now_vip_status.top_level!==0"
                   style="background-color: #6690fc;height: 35px;color: white;width: 80px;border-radius: 8px;line-height: 35px;cursor: pointer">
                  套餐升级</p>
              </div>
              <div class="smallTitle flex">
                <p class="smallTitleP">选择套餐类型</p><img class="smallTitleImg" src="../../assets/tip.png" alt="">
              </div>
              <div class="flex">
                <div style="width: 23.1%;" v-for="(item,index) in timeList" @click="checkVip(index)">
                  <div class="time_type"
                       style="background: linear-gradient( 236deg, #FEE9D6 0%, #F5CD96 100%);color: #d26934;border: #d26934 2px solid;position: relative"
                       v-if="timeType===index">
                    {{ item.name }}
                    <img style="position: absolute;width: 25px;height: 25px;right: 0;bottom: 0"
                         src="../../assets/isCheck.png" alt="">
                  </div>
                  <div class="time_type" @click="timeType=index" v-else>
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="smallTitle flex" style="margin-top: 2.5vh">
                <p class="smallTitleP">选择时长</p><img class="smallTitleImg" src="../../assets/tip.png" alt="">
              </div>
              <div>
                <div style="width: 101.5%;position: relative" v-for="(item,index) in 3">
                  <div class="tao_type"
                       :class="[ taoType===index?'tao_type tao_type_click': 'tao_type' ]">
                    <img class="percent_img" src="../../assets/percent.png" alt="">
                    <p class="percent_p" v-if="index===0&&member_now.month_discounted_at * 10%10>0">{{ member_now.month_discounted_at * 10|oneDecimals }}折</p>
                    <p class="percent_p" v-if="index===0&&member_now.month_discounted_at * 10%10===0">{{ member_now.month_discounted_at * 10|zeroDecimals }}折</p>
                    <p class="percent_p" v-if="index===1&&member_now.quarter_discounted_at * 10%10>0">{{ member_now.quarter_discounted_at * 10|oneDecimals }}折</p>
                    <p class="percent_p" v-if="index===1&&member_now.quarter_discounted_at * 10%10===0">{{ member_now.quarter_discounted_at * 10|zeroDecimals }}折</p>
                    <p class="percent_p" v-if="index===2&&member_now.year_discounted_at * 10%10>0">{{ member_now.year_discounted_at * 10|oneDecimals }}折</p>
                    <p class="percent_p" v-if="index===2&&member_now.year_discounted_at * 10%10===0">{{ member_now.year_discounted_at * 10|zeroDecimals }}折</p>
                    <img class="tao_type_img" src="../../assets/card.png" alt="">
                    <p class="tao_type_title" v-if="index===0">月卡</p>
                    <p class="tao_type_title" v-if="index===1">季卡</p>
                    <p class="tao_type_title" v-if="index===2">年卡</p>
                    <p class="tao_type_tip" v-if="index===0">
                      {{ member_now.total_word_count_per_month / 10000|zeroDecimals }}万积分每月</p>
                    <p class="tao_type_tip" v-if="index===1">
                      {{ member_now.total_word_count_per_month / 10000 * 3|zeroDecimals }}万积分每季</p>
                    <p class="tao_type_tip" v-if="index===2">
                      {{ member_now.total_word_count_per_month / 10000 * 12|zeroDecimals }}万积分每年</p>
                    <p class="tao_type_money">
                      <span v-if="index===0"
                            style="color: #C3131E;font-weight: bolder">{{ member_now.month_price |twoDecimals }}</span>
                      <span v-if="index===1"
                            style="color: #C3131E;font-weight: bolder">{{
                          member_now.quarter_price / 3 |twoDecimals
                        }}</span>
                      <span v-if="index===2"
                            style="color: #C3131E;font-weight: bolder">{{
                          member_now.year_price / 12 |twoDecimals
                        }}</span>
                      元<span style="font-size: 13px;">/月</span></p>
                    <p class="taoBtn" @click="taoCheck(index)">立即购买</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="trapezoid===1">
              <div class="smallTitle flex">
                <p class="smallTitleP">选择加量包</p><img class="smallTitleImg" src="../../assets/tip.png" alt="">
              </div>
              <div class="flex">
                <div style="width: 23.5%;position: relative;" v-for="(item,index) in word_count_packages.length">
                  <div :class="[ taoType1===index?'tao_type1 tao_type_click': 'tao_type1' ]">
                    <p class="tao_type_money1">
                      <span style="color: #C3131E;font-weight: bolder">{{ word_count_packages[index].price |twoDecimals }}</span>
                      元<span style="font-size: 13px;"></span></p>
                    <p class="tao_type_tip1">{{ word_count_packages[index].total_word_count / 10000|twoDecimals  }}w积分</p>
                    <p class="taoBtn1" @click="taoCheck1(index)">立即购买</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="trapezoid===2">
              <div class="flex" style="width: 55%;justify-content: space-between">
                <i class="el-icon-arrow-left" style="font-size: 25px;margin-top: 5px;cursor: pointer"
                   @click="trapezoidChange(0)"></i>
                <p style="line-height: 0;font-size: 16px;font-weight: bold">套餐升级</p>
              </div>
              <p style="text-align: left;height: 10px;font-weight: bold;margin-bottom: 30px">当前套餐剩余:<span
                  style="color: #bd1e1f"><span v-if="now_vip_status.top_level===-1">未开通</span>
                  <span v-if="now_vip_status.top_level===1">初级</span>
                  <span v-if="now_vip_status.top_level===2">中级</span>
                  <span v-if="now_vip_status.top_level===3">高级</span>
                  <span v-if="now_vip_status.top_level===4">旗舰版</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='month'">-月卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='quarter'">-季卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='year'">-年卡</span></span></p>
              <div class="old_tao flex">
                <p>剩余天数</p>
                <p style="color: #3668cb">{{ now_vip_status.date_in }}天</p>
              </div>
              <div class="old_tao flex">
                <p>剩余积分</p>
                <p style="color: #3668cb">{{ now_vip_status.remain_word_count }}积分</p>
              </div>
              <p style="text-align: left;height: 10px;font-weight: bold">选择要升级的套餐</p>
              <div class="flex">
                <div v-for="(item,index) in add_level_packages">
                  <div @click="taoType2Change(index)"
                       :class="[ taoType2===index?'tao_type2 tao_type2_click': 'tao_type2' ]">
                    <img style="position: absolute;width: 60%;height: 35px;right: 20%;top: 0;z-index: 2"
                         src="../../assets/head.png" alt="">
                    <p v-if="item.level===1" class="vip_font">初级</p>
                    <p v-if="item.level===2" class="vip_font">中级</p>
                    <p v-if="item.level===3" class="vip_font">高级</p>
                    <p v-if="item.level===4" class="vip_font">旗舰版</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='month'" class="taoType2_money">{{ item.month_price }}￥</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='quarter'" class="taoType2_money">{{ item.quarter_price }}￥</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='year'" class="taoType2_money">{{ item.year_price }}￥</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='month'" class="taoType2_num">{{ item.total_word_count_per_month / 10000|twoDecimals }}万积分</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='quarter'" class="taoType2_num">{{ item.total_word_count_per_month*3 / 10000|twoDecimals }}万积分</p>
                    <p v-if="now_vip_status.current_level_order_time_len==='year'" class="taoType2_num">{{ item.total_word_count_per_month*12 / 10000|twoDecimals }}万积分</p>
<!--                    <p v-if="now_vip_status.current_level_order_time_len==='month'" class="taoType2_tip">低至{{ item.month_per_thousand_wc_price }}/千积分</p>-->
<!--                    <p v-if="now_vip_status.current_level_order_time_len==='quarter'" class="taoType2_tip">低至{{ item.quarter_per_thousand_wc_price }}/千积分</p>-->
<!--                    <p v-if="now_vip_status.current_level_order_time_len==='year'" class="taoType2_tip">低至{{ item.year_per_thousand_wc_price }}/千积分</p>-->
                    <img v-if="taoType2===index" style="position: absolute;width: 25px;height: 25px;right: 0;bottom: 0"
                         src="../../assets/isCheck.png" alt="">
                  </div>
                </div>
              </div>
              <div class="old_tip">
                <p>
                  请根据您需要的积分量，购买对应等级会员。若当前会员等级积分不足使用，请购买加量包获取积分，或点击升级套餐，原有套餐剩余积分及时长，都将折算为抵扣金额用于套餐升级，并立即刷新会员时长及积分。（若重复购买套餐，新套餐将在原有会员时长后进行顺延，无法立刻刷新）
                </p>
              </div>
            </div>
          </div>

          <div class="smallItem_right" style="position: relative">
            <div v-if="trapezoid===0||trapezoid===1">
              <p class="smallItem_right_p">
                套餐升级后原有套餐将会被折算为一定金额进行抵扣，并以升级套餐的时间点开始重新刷新套餐积分及会员时长。（套餐升级只能在同类时长会员内进行升级，如初级会员月卡可升级为中级会员月卡，而不能升级为中级会员年卡。）
              </p>
              <div class="right_line"></div>
            </div>
            <div v-if="trapezoid===2" style="line-height: 15px">
              <p style="height: 35px;font-weight: bolder">套餐详情</p>
              <div style="color: darkgrey;height: 45px;display: flex;justify-content: space-between">
                <p>抵扣套餐</p>
                <p>套餐金额</p>
              </div>
              <div class="flex" style="justify-content: space-between;font-weight: bolder;height: 50px;line-height: 0">
                <p>
                  <span v-if="add_level_now.level===1">初级</span>
                  <span v-if="add_level_now.level===2">中级</span>
                  <span v-if="add_level_now.level===3">高级</span>
                  <span v-if="add_level_now.level===4">旗舰版</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='month'">-月卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='quarter'">-季卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='year'">-年卡</span>
                </p>
                <p>￥{{ up_packages_ole_money }}</p>
              </div>
              <div style="color: darkgrey;height: 45px;display: flex;justify-content: space-between">
                <p>抵扣套餐</p>
                <p>抵扣金额</p>
              </div>
              <div class="flex"
                   style="height: 35px;line-height: 0;justify-content: space-between;font-weight: bolder;border-bottom: solid 1px rgba(231,236,248,0.9)">
                <p><span v-if="now_vip_status.top_level===1">初级</span>
                  <span v-if="now_vip_status.top_level===2">中级</span>
                  <span v-if="now_vip_status.top_level===3">高级</span>
                  <span v-if="now_vip_status.top_level===4">旗舰版</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='month'">-月卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='quarter'">-季卡</span>
                  <span v-if="now_vip_status.current_level_order_time_len==='year'">-年卡</span></p>
                <p>-￥{{ up_packages_reduce_price }}</p>
              </div>
              <div class="flex" style="justify-content: space-between;font-weight: bolder">
                <p>支付总计</p>
                <p style="color: #c1131f">￥{{ up_packages_now_price }}</p>
              </div>
            </div>
            <div class="pay_ewm" style="width: 103%;margin-top: 5%">
              <p style="color: #4246c8;font-weight: bolder" v-if="trapezoid===0">扫码支付</p>
              <p>请使用微信扫描二维码进行支付</p>
              <p style="font-weight: bold;font-size: 32px;color: #C3131E;line-height: 0;position: relative;right: 15px">
                ￥{{ trapezoid === 0 ? member_money : trapezoid === 1 ? tao_check_money : up_packages_now_price }}</p>
<!--              <img class="ewm_img" src="../../assets/ewm.png" alt="">-->
              <div id="qrcodeImg" style="margin-left: 4.5vw"></div>
<!--              <div class="flex" style="justify-content: space-around">-->
<!--                <div class="pay_way flex">-->
<!--                  <img class="pay_way_img" src="../../assets/zhi.png" alt="">-->
<!--                  <p class="pay_way_p">支付宝支付</p>-->
<!--                </div>-->
<!--                <div class="pay_way flex">-->
<!--                  <img class="pay_way_img" src="../../assets/wei.png" alt="">-->
<!--                  <p class="pay_way_p">微信支付</p>-->
<!--                </div>-->
<!--              </div>-->

              <p style="margin-top: 2vh;position: relative;right: 10px;font-size: 13px">支付即代表同意并接受 个人订阅服务协议</p>
            </div>
          </div>
        </div>
      </div>
      <div class="current2" v-if="current===1">
        <div class="smallItem2">
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">头像</p>
            <input type="file" @change="onFileChange" style="display: none" ref="fileInput">
            <div class="img_box">
              <img class="img_box_img" :src="nowUser.avatar" alt="">
              <p class="img_box_p" @click="triggerFileUpload">点击上传</p>
            </div>
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">用户昵称</p>
            <input class="smallItem2_content_tip2" style="border: darkgrey solid 1px" :disabled="!disable_A" v-if="disable_A" v-model="nowUser.nickname">
            <input class="smallItem2_content_tip2" :disabled="!disable_A" v-else v-model="nowUser.nickname">
            <p class="smallItem2_content_tip3" @click="disable_change(1)">{{ !disable_A?'点击修改':'确认' }}</p>
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">用户ID</p>
            <span class="smallItem2_content_tip2" style="width: auto;position: relative;top: 5px;">{{ nowUser.id }}</span>
            <img src="../../assets/copy.png" @click="copyText"
                 style="width: 20px;height: 20px;position: relative;left: 10px;top: 21px;cursor: pointer" alt="">
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">手机号码</p>
            <p v-if="nowUser.mobile" class="smallItem2_content_tip2">{{nowUser.mobile}}</p>
            <p v-else class="smallItem2_content_tip2">暂未绑定手机号码</p>
            <p v-if="nowUser.mobile" class="smallItem2_content_tip3" @click="changeTelShow">点击换绑</p>
            <p v-else class="smallItem2_content_tip3">点击绑定</p>
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">微信绑定</p>
            <p class="smallItem2_content_tip2">{{nowUser.third.wechat?'已绑定':'未绑定'}}</p>
            <p class="smallItem2_content_tip3" @click="wechatChange(nowUser.third.wechat)">{{nowUser.third.wechat?'解除绑定':'点击绑定'}}</p>
          </div>
          <div class="flex smallItem2_content" v-if="nowUser.is_set_password">
            <p class="smallItem2_content_tip1">旧密码</p>
            <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px" v-model="change_pwd_data.oldpassword"/>
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">新密码</p>
            <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px" v-model="change_pwd_data.newpassword"/>
          </div>
          <div class="flex smallItem2_content">
            <p class="smallItem2_content_tip1">再次确认</p>
            <input type="password" class="smallItem2_content_tip2" style="border: darkgrey solid 1px" v-model="change_pwd_data.renewpassword"/>
          </div>
          <div class="save" @click="change_pwd">保存</div>
          <!--          <el-divider>-->
          <!--            <img src="../../assets/vip.png" style="width: 100px;height: 20px;position: relative;cursor: pointer" alt="">-->
          <!--          </el-divider>-->
          <!--          <el-tabs v-model="activeName" @tab-click="handleClick">-->
          <!--            <el-tab-pane label="加入团队" name="first"></el-tab-pane>-->
          <!--            <el-tab-pane label="添加队友" name="second"></el-tab-pane>-->
          <!--          </el-tabs>-->
          <!--          <div class="flex smallItem2_content" style="margin-top: 2vh;width: 100%;">-->
          <!--            <input placeholder="请输入用户ID" class="saveInput">-->
          <!--            <div class="save">申请加入</div>-->
          <!--          </div>-->
<!--          <div class="flex smallItem2_content"-->
<!--               style="margin-top: 15vh;width: 100%;text-align: left;cursor: pointer;left: 23%">-->
<!--            <p class="smallItem2_content_tip1">注销账号</p>-->
<!--            <div style="line-height: 15px;font-size: 14px;margin-top: 15px">-->
<!--              <p style="color: #4D71FF">立即注销></p>-->
<!--              <p style="color: #939393">注销账号后将无法使用任何服务,并且相关数据将永久丢失.此操作不可逆,请谨慎操作!</p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="current2" v-if="current===2">
        <div class="smallItem2" style="position: relative;height: 98%;width: 100%;margin: 0;">
          <div class="dateSelect">
            <i class="el-icon-date"></i>
            <span class="span">日期：</span>
            <el-select class="el-select" v-model="params" placeholder="请选择" @change="dateChange">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>

          <div class="custom-scrollbar" style="height: 78%;overflow-y: scroll;margin-top: 30px">
            <div style="height: 55px;background-color: #F1F6FF;width: 100%;display: flex;line-height: 55px">
              <div style="width: 30%">套餐名称</div>
              <div style="width: 15%">充值时间</div>
              <div style="width: 35%">订单编号</div>
              <div style="width: 10%">充值金额(元)</div>
            </div>
            <div :key="index" v-for="(item,index) in booklist2"
              style="height: 55px;width: 100%;display: flex;line-height: 55px;border-bottom: #E7E7E7 1px solid;font-size: 15px">
              <div class="slh" style="width: 30%;color: #B39354">
                {{item.order.level===1?'初级':item.order.level===2?"中级":item.order.level===3?"高级":item.order.level===4?"旗舰版":""}}
                {{ item.order.order_time_len==='month'?'——月卡':item.order.order_time_len==='quarter'?'——季卡':item.order.order_time_len==='year'?'——年卡':'' }}{{ item.order.type==='member'?'——购买会员':'购买增量包' }}
              </div>
              <div class="slh" style="width: 15%">{{ item.payment_time | formatDate }}</div>
              <div class="slh" style="width: 35%; overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;">{{ item.orderno }}</div>
              <div class="slh" style="width: 10%;color: #1D9461">{{ item.actual_paid_amount }}</div>
            </div>
            <el-empty v-if="booklist2.length===0" description="暂无充值记录" style="margin-top: 10vh"></el-empty>
          </div>
          <div class="feet">
            <p style="line-height: 0px">
              共计{{ moneyMoney }}条/第{{ payment_record_data.page }}页
            </p>
            <el-pagination
                background
                @size-change="handlemoneySizeChange"
                @current-change="handleMoneyCurrentChange"
                :current-page.sync="payment_record_data.page"
                :page-sizes="[10]"
                layout="sizes, prev, pager, next"
                :total="moneyMoney">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="current2" v-if="current===3">
        <div class="smallItem2" style="position: relative;height: 98%;width: 100%;margin: 0;">
          <div class="custom-scrollbar" style="height: 85%;overflow-y: scroll;margin-top: 30px">
            <div style="height: 55px;background-color: #F1F6FF;width: 100%;display: flex;line-height: 55px">
              <div style="width: 33%">服务类型</div>
<!--              <div style="width: 25%">用户ID</div>-->
              <div style="width: 33%">提交时间</div>
              <div style="width: 33%">消耗积分</div>
            </div>
            <div :key="index" v-for="(item,index) in booklist"
                 style="height: 55px;width: 100%;display: flex;line-height: 55px;border-bottom: #E7E7E7 1px solid;font-size: 15px">
              <div class="slh" style="width: 33%;">AI检测</div>
<!--              <div class="slh" style="width: 25%">{{ item.word_count }}</div>-->
              <div class="slh" style="width: 33%">{{ item.usage_time|formatDate }}</div>
              <div class="slh" style="width: 33%;color: #1D9461">{{ item.word_count }}</div>
            </div>
            <el-empty v-if="booklist.length===0" description="暂无消费记录" style="margin-top: 10vh"></el-empty>
          </div>
          <div class="feet">
            <p style="line-height: 0">
              共计{{ usageTotal }}条/第{{ usagePage }}页
            </p>
            <el-pagination
                background
                @size-change="handleUsageSizeChange"
                @current-change="handleUsageCurrentChange"
                :current-page.sync="usagePage"
                :page-sizes="[10]"
                layout="sizes, prev, pager, next"
                :total="usageTotal">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Change_tel from "@/components/change_tel.vue";
import Change_we from "@/components/change_we.vue";
import moment from 'moment'
import top from "@/components/Top.vue";
import Login from "@/components/Login.vue";
import {
  available_packages, changeavatar, changenickname, changepwd, login_status,
  order_pay, order_pay_result,
  order_status, order_upgrade, payment_record, unbind,
  user_info,
  user_order,
  usermember_use
} from "@/api/1";
import QRCode from 'qrcodejs2';
import axios from "axios";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {Login, top,Change_tel,Change_we},
  data() {
    return {
      params:1,
      options:[
        {
          value: 1,
          label: '近一个月'
        }, {
          value: 2,
          label: '近三个月'
        }, {
          value: 3,
          label: '近一年'
        }, {
          value: 4,
          label: '全部'
        }
      ],
      disable_A:false,
      moneyMoney:0,
      disable_B:false,
      disable_C:false,
      change_pwd_data:{
        oldpassword:'',
        newpassword:'',
        renewpassword:'',
      },
      payment_record_data:{
        page: 1,
        limit:10
      },
      current: 0,
      usagePage: 1,
      Change_tel_show:false,
      usageTotal: 0,
      nowUser: {},
      trapezoid: 0,
      timeType: 0,
      vipType: 0,
      taoType: 0,
      taoType1: 0,
      taoType2: '',
      click: 3,
      activeName: 'first',
      checkList: [],
      currentPage: 1,
      booklist: [],
      booklist2: [
        {
          name: "专业版-月套餐",
          nums: "32131425564636",
          time: "2024-06-07 12：12",
          state: 2311,
          // name:"AI检测",
        }],
      timeList: [],
      taoList: [],
      taoList2: [],
      tabList: [
        {
          name: '会员充值'
        },
        {
          name: '个人资料'
        },
        {
          name: '充值记录'
        },
        {
          name: '消费记录'
        }
      ],
      member_packages: [],
      member_now: [],
      up_now: [],
      member_money: 0,
      tao_check_money: 0,
      count_packages_money: 0,
      up_packages_ole_money: 0,
      up_packages_now_price: 0,
      up_packages_reduce_price: 0,
      up_packages_id: 0,
      now_vip_status: {},
      word_count_packages: [],
      add_level_packages: [],
      add_level_now: {},
      order_id:'',
      oQRCode:'',
    };
  },
  created() {
    // console.log(this.isMobile())
    if( this.isMobile() ){
      this.$router.push("/recordsTel")
    }else {
      this.$router.push("/records")
    }
    const that = this
    setTimeout(() => {
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
      that.initDataMethod()
      } else {
        that.$refs.tops.openLogin()
      }
    }, 500);
  },

  methods: {
    //该方法用于判断是否进入手机端
    isMobile(){
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    changeTelShow(){
      this.$refs.changeTel.changeTelShow()
    },
    changeWeShow(){
      this.$refs.changeWe.changeWeShow()
    },
    async wechatChange(b) {
      const that = this
      if (!b) {
        this.$refs.changeWe.changeWeShow()
      } else {
        const confirmResult = await this.$confirm('此操作将解除微信绑定, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        /*如果用户确认打印confirm,如果用户取消显示cancel*/
        if (confirmResult !== 'confirm') {
          return that.$message.info('已取消解除微信绑定')
        } else {
          const user = that.nowUser
          user.third.wechat=false
          localStorage.setItem("userinfo",JSON.stringify(user))
          that.nowUser=user
          unbind().then(res => {
            return that.$message.info('已成功解除微信绑定')
          })
        }
      }
    },
    bind(){
      const user = this.nowUser
      user.third.wechat=true
      localStorage.setItem("userinfo",JSON.stringify(user))
      this.nowUser=user
      this.$message.info('您已成功绑定微信')
    },
    initDataMethod(){
      const that = this
      order_status().then(res => {
        this.now_vip_status = res.data.data
        const diff = res.data.data.current_level_end_at - new Date().getDate()
        this.now_vip_status.date_in = Math.floor(diff / (60 * 60 * 24));
        available_packages().then(res => {
          that.member_packages = res.data.data.member_packages
          that.add_level_packages=[]
          that.timeList=[]
          for (let i = 0; i < that.member_packages.length; i++) {
            if (that.now_vip_status.top_level<that.member_packages[i].level){
              that.add_level_packages.push(that.member_packages[i])
            }
            if (that.member_packages[i].level===1){
              that.timeList.push({
                name: "初级",
                level:1
              })
            }
            if (that.member_packages[i].level===2){
              that.timeList.push({
                name: "中级",
                level:2
              })
            }
            if (that.member_packages[i].level===3){
              that.timeList.push({
                name: "高级",
                level:3
              })
            }
            if (that.member_packages[i].level===4){
              that.timeList.push({
                name: "旗舰版",
                level:4
              })
            }
            if (that.vipType + 1 === that.member_packages[i].level) {
              that.member_now = that.member_packages[i]
              that.up_now = that.member_packages[i]
            }
          }
          if (that.taoType === 0) {
            that.member_money = that.member_now.month_price
          }
          if (that.taoType === 1) {
            that.member_money = that.member_now.quarter_price
          }
          if (that.taoType === 2) {
            that.member_money = that.member_now.year_price
          }
          that.userOrder()

          // this.count_packages_money=this.word_count_packages[0].price
          let word_count_packages = []
          for (let s = 0; s < res.data.data.word_count_packages.length; s++) {
            if (res.data.data.word_count_packages[s].level!==0&&res.data.data.word_count_packages[s].marketing_type===0){
              word_count_packages.push(res.data.data.word_count_packages[s])
            }
          }
          that.word_count_packages = word_count_packages
          that.tao_check_money = that.word_count_packages[0].price
          // console.log(localStorage.getItem("up"))
          if (localStorage.getItem("up")==='isTrue'){
            that.trapezoidChange(2)
            localStorage.setItem("up",null)
          }
        });
      })
    },
    dateChange(e){
      // console.log(e)
      this.get_payment_record()
    },
    triggerFileUpload() {
      this.$refs.fileInput.click(); // 触发文件上传
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      this.uploadAvatar(file);
    },
    uploadAvatar(file) {
      const that = this
      // console.log(file)
      const formData = new FormData();
      formData.append('file', file);
      formData.append("category", "custom",);
      if (file && file.type.startsWith('image/')) {
        axios.post('https://sj.yundianai.com/api/common/upload', formData, {
          headers: {
            'Token': JSON.parse(localStorage.getItem("userinfo")).token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if(response.data.code===1){
            const user = JSON.parse(localStorage.getItem("userinfo"))
            user.avatar=response.data.data.fullurl
            that.nowUser.avatar=response.data.data.fullurl
            localStorage.setItem("userinfo",JSON.stringify(user))
            changeavatar({avatar:that.nowUser.avatar}).then(res => {
              // console.log(res)
              if (res.data.code===1){
                that.$message.success("您已成功修改头像")
                that.$refs.tops.reLoad()
              }else {
                that.$message.error(res.data.msg)
              }
            })

            // setTimeout(() => {
            //   this.$router.go("/records?current=1")
            //   // this.current=1
            // }, 1000);
          }
        }).catch(error => {
          // console.error('上传失败:', error);
          that.$message.error('上传失败')
        });
      }else {
        that.$message.error("头像只能是文件格式")
      }
    },
    copyText() {
      navigator.clipboard.writeText();
      // 兼容非安全域，非安全域下不可使用navigator.clipboard.writeText
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(this.nowUser.id).then(() => {
          this.$message.success("您的用户id已经复制到剪切板")
        }).catch((error) => {
          // console.error("复制失败：", error);
        })
      } else {

      }
    },
    change_pwd(){
      if (this.change_pwd_data.newpassword.length>=6){
        changepwd(this.change_pwd_data).then(res => {
          // console.log(res)
          if (res.data.code===1){
            this.$message.success("密码修改成功，请重新登录")
            localStorage.setItem("userinfo", null)
            setTimeout(() => {
              this.$router.push("/register")
            }, 1000);
          }else {
            this.$message.error(res.data.msg)
          }
        })
      }else {
        this.$message.error("新密码的最小长度为6")
      }
    },
    disable_change(index){
      const that = this
      if (Number(index)===1){
        if (that.disable_A){
          if (that.nowUser.nickname.length===0||that.nowUser.nickname.length>10||that.nowUser.nickname.includes(' ')){
            that.$message.error("昵称为字数为1-10，且不包含空格")
          }else {
            changenickname({nickname:that.nowUser.nickname}).then(res => {
              if (res.data.code===1){
                const user = JSON.parse(localStorage.getItem("userinfo"))
                user.nickname=that.nowUser.nickname
                localStorage.setItem("userinfo",JSON.stringify(user))
                that.$message.success("您已成功修改昵称")
                that.$refs.tops.reLoad()
                // setTimeout(() => {
                //   this.$router.go("/records?current=1")
                // }, 1000);
              }else {
                that.$message.error(res.data.msg)
              }
              that.disable_A=false
            })
          }
        }else {
          that.disable_A=true
        }
      }
    },
    get_order_upgrade_money(){
        order_upgrade({package_id:this.add_level_packages[this.taoType2].id}).then(res => {
          // console.log("order_upgrade",res.data.data)
          this.up_packages_id=res.data.data.order_id
          this.up_packages_ole_money=res.data.data.origin_price
          this.up_packages_now_price=res.data.data.price
          this.up_packages_reduce_price=res.data.data.price_deduction
          this.userOrder()
        })
    },
    qrcode(url) {  // 前端根据 URL 生成微信支付二维码
      document.getElementById('qrcodeImg').innerHTML = ''
      this.oQRCode = new QRCode('qrcodeImg', {
        width: 180,
        height: 180,
        text: url,
        colorDark: '#000',
        colorLight: '#fff'
      });
    },
    trapezoidChange(index){
      const that = this
      this.trapezoid=Number(index)
      if (Number(index)===0) {
        that.$router.go(0)
      }
      if (Number(index)===2){
        order_status().then(res => {
          this.now_vip_status = res.data.data
          const diff = res.data.data.current_level_end_at - parseInt(new Date().getTime()/1000);
          this.now_vip_status.date_in = Math.floor(diff / (60 * 60 * 24));
          available_packages().then(res => {
            that.member_packages = res.data.data.member_packages
            that.add_level_packages = []
            that.timeList = []
            for (let i = 0; i < that.member_packages.length; i++) {
              if (that.now_vip_status.top_level < that.member_packages[i].level) {
                that.add_level_packages.push(that.member_packages[i])
              }
            }
            this.taoType2=0
            this.add_level_now=this.add_level_packages[0]
            this.get_order_upgrade_money()
          })
        })
      }
      this.userOrder()
    },
    taoType2Change(index) {
      this.taoType2 = index
      this.add_level_now=this.add_level_packages[this.taoType2]
      this.get_order_upgrade_money()
      this.userOrder()
    },
    checkVip(index) {
      this.vipType = index
      this.taoType = 0
      for (let i = 0; i < this.member_packages.length; i++) {
        if (this.vipType + 1 === this.member_packages[i].level) {
          this.member_now = this.member_packages[i]
        }
      }
      if (this.taoType === 0) {
        this.member_money = this.member_now.month_price
      }
      if (this.taoType === 1) {
        this.member_money = this.member_now.quarter_price
      }
      if (this.taoType === 2) {
        this.member_money = this.member_now.year_price
      }
      this.userOrder()
    },
    taoCheck(index) {
      this.taoType = Number(index)
      if (this.taoType === 0) {
        this.member_money = this.member_now.month_price
      }
      if (this.taoType === 1) {
        this.member_money = this.member_now.quarter_price
      }
      if (this.taoType === 2) {
        this.member_money = this.member_now.year_price
      }
      this.userOrder()
    },
    taoCheck1(index) {
      this.taoType1 = Number(index)
      this.tao_check_money = this.word_count_packages[index].price
      this.userOrder()
    },
    userOrder(){
      // console.log(this.trapezoid)
      const  that = this
      if (that.trapezoid===2){
          order_pay({type: 1, order_id: this.up_packages_id}).then(res => {
            that.qrcode(res.data.data.code_url);
            const payment_record_id = res.data.data.payment_record_id
            let intervalId = setInterval(function () {
              const current = that.current
              if(current!==0) {
                clearInterval(intervalId)
              }
              if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
                order_pay_result({payment_record_id:payment_record_id}).then(res => {
                // console.log("res.data",res.data.data.is_paid)
                  if(res.data.data.is_paid===1){
                    clearInterval(intervalId)
                    that.$message({
                      message: '支付成功',
                      type: 'success'
                    });
                    that.$refs.tops.reLoad()
                    that.trapezoidChange(2)
                  }

              })}else {
              clearInterval(intervalId)
            }
            }, 1000)
          });
      }
      if (that.trapezoid===1){
        user_order({type:2,package_id:that.word_count_packages[that.taoType1].id}).then(res => {
          order_pay({type: that.trapezoid + 1, order_id: res.data.data.order_id}).then(res => {
            that.qrcode(res.data.data.code_url);
            const payment_record_id = res.data.data.payment_record_id
            let intervalId = setInterval(function () {
              if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
                const current = that.current
                if(current!==0) {
                  clearInterval(intervalId)
                }
                order_pay_result({payment_record_id:payment_record_id}).then(res => {
                // console.log("res.data",res.data.data.is_paid)

                  if(res.data.data.is_paid===1){
                    clearInterval(intervalId)
                    that.$message({
                      message: '支付成功',
                      type: 'success'
                    });
                    that.$refs.tops.reLoad()
                    that.userOrder()
                  }
              })}else {
              clearInterval(intervalId)
            }
            }, 1000)
          });
        });
      }
      if (that.trapezoid===0){
        user_order({type:1,package_id:that.member_now.id,order_time_len:that.taoType===0?'month':that.taoType === 1?'quarter':'year'}).then(res => {
          order_pay({type: that.trapezoid + 1, order_id: res.data.data.order_id}).then(res => {
            that.qrcode(res.data.data.code_url);
            const payment_record_id = res.data.data.payment_record_id
            let intervalId  = setInterval(function () {
              if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
                const current = that.current
                if(current!==0) {
                  clearInterval(intervalId)
                }
                order_pay_result({payment_record_id:payment_record_id}).then(res => {
                  // console.log("res.data",res.data.data.is_paid)
                    if(res.data.data.is_paid===1){
                      clearInterval(intervalId)
                      that.$message({
                        message: '支付成功',
                        type: 'success'
                      });
                      that.$refs.tops.reLoad()
                      setTimeout(() => {
                        that.$router.go(0)
                      }, 1000);
                    }
                  })}else {
                clearInterval(intervalId)
              }
              }, 1000)
          });
        });
      }

    },
    currentChange(index) {
      this.current = Number(index)
      clearInterval(this.intervalId)
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {

        if (this.current === 0) {
          this.trapezoid = 0
          this.userOrder()
        }
        if (this.current === 1) {
            this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
        }if (this.current === 2) {
          this.get_payment_record()
        }
        if (this.current === 3) {
          usermember_use({page: this.usagePage,limit: 10}).then(res => {
            this.usageTotal=res.data.data.total
            this.booklist=res.data.data.rows
          });
        }
      } else {
        this.$refs.tops.openLogin()
      }
    },
    get_payment_record(){
      // console.log(this.params)
      this.payment_record_data.end_at = new Date().getTime();
      if (this.params===1){
        this.payment_record_data.start_at=moment().subtract(1, 'months')._d.getTime()
        this.payment_record_data.start_at=parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params===2){
        this.payment_record_data.start_at=moment().subtract(2, 'months')._d.getTime()
        this.payment_record_data.start_at=parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params===3){
        this.payment_record_data.start_at=moment().subtract(12, 'months')._d.getTime()
        this.payment_record_data.start_at=parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params===4){
        this.payment_record_data.start_at=-1
      }
      payment_record(this.payment_record_data).then(res => {
        // console.log(res.data.data)
        this.moneyMoney = res.data.data.total
        this.booklist2 = res.data.data.rows
      });
    },
    handleUsageSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handlemoneySizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleUsageCurrentChange(val) {
      this.usagePage=val
      usermember_use({page: this.usagePage,limit: 10}).then(res => {
        this.usageTotal=res.data.data.total
        this.booklist=res.data.data.rows
      });``
    },
    handleMoneyCurrentChange(val) {
      this.payment_record_data.page=val
      this.get_payment_record()
    },
    handleClick(tab, event) {
      // console.log(tab._props);
    }
  },
};
</script>

<style scoped>
.trapezoid {
  position: relative;
  width: 89%; /* 梯形的宽度 */
  height: 50px; /* 梯形的高度 */
  border: solid 2px #4347c9;
  margin-bottom: 10px;
}

.trapezoid-right {
  cursor: pointer;
  color: #4246c8;
  text-align: center;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 47%;
  width: 49%;
  height: 0;
  border-bottom: 50px solid rgba(0, 0, 0, 0); /* 梯形顶部的高度 */
  border-left: 25px solid transparent; /* 左边直角的宽度 */
  /*border-right: 25px solid transparent; !* 右边直角的宽度 *!*/
}

.trapezoid-right2 {
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 0;
  border-bottom: 50px solid #4347c9; /* 梯形顶部的高度 */
  border-left: 25px solid transparent; /* 左边直角的宽度 */
  /*border-right: 25px solid transparent; !* 右边直角的宽度 *!*/
}

.trapezoid-left {
  cursor: pointer;
  color: #fff;
  text-align: center;
  /*z-index: -1;*/
  position: absolute;
  top: 0;
  left: 0;
  border-top: 50px solid #4347c9;
  border-right: 25px solid transparent;
  height: 0;
  width: 47%;
}

.trapezoid-left2 {
  cursor: pointer;
  color: #4246c8;
  text-align: center;
  /*z-index: -1;*/
  position: absolute;
  top: 0;
  left: 0;
  border-top: 50px solid rgba(0, 0, 0, 0);
  border-right: 25px solid transparent;
  height: 0;
  width: 47%;
}

/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}

.feet {
  display: flex;
  justify-content: space-between;
  height: 80px;
  position: absolute;
  /*background-color: black;*/
  bottom: 0;
  width: 95%;
  margin-left: 2.5%;
}

.save {
  width: 80px;
  height: 35px;
  line-height: 35px;
  margin-bottom: 4vh;
  margin-top: 3vh;
  cursor: pointer;
  color: white;
  margin-left: 35%;
  background: #4D71FF;
  border-radius: 12px 12px 12px 12px;
}

.saveInput {
  height: 38px;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #4D71FF;
  background-color: #f3f3f4;
  padding-left: 20px;
  width: 300px;
  margin-right: 20px
}

.img_box {
  margin-left: 30px;
  cursor: pointer;
  width: 100px;
}

.img_box_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 20px;
}

.img_box_p {
  line-height: 0px;
  position: relative;
  bottom: 10px;
  color: #ABABAB;
  font-size: 15px;
}

.smallItem2_content {
  line-height: 30px;
  width: 50%;
  left: 27%;
  position: relative;
}

.smallItem2_content_tip1 {
  font-size: 15px;
  width: 20%;
  text-align: left;
}

.smallItem2_content_tip2 {
  background-color: rgba(0,0,0,0);
  font-size: 15px;
  height:42px;
  margin-top: 10px;
  width: 60%;
  text-align: left;
  border: none;
}
.smallItem2_content_tip2:focus{
  outline:none;
  border:none;
  box-shadow: 0 0 0 2px transparent;
}

.smallItem2_content_tip3 {
  position: absolute;
  font-size: 15px;
  cursor: pointer;
  right: 0;
  color: #4D71FF;
}

.ewm_img {
  width: 45%;
  min-width: 110px;
  height: auto;
}

.pay_way_img {
  width: 2.5vh;
  height: 2.5vh;
  margin-top: 10px;
  min-height: 25px;
  min-width: 25px;
}

.pay_way_p {
  line-height: 12px;
  margin-left: 10px;
}

.time_type {
  width: 88%;
  height: 8vh;
  min-height: 62px;
  margin-top: 15px;
  font-weight: bolder;
  padding-top: 14%;
  box-sizing: border-box;
  font-size: 1rem;
  border-radius: 12px 12px 12px 12px;
  border: 2px solid #4347c9;
  color: #4347c9;
  cursor: pointer;
}

.taoBtn1 {
  width: 70%;
  height: 25px;
  color: white;
  font-size: 12px;
  line-height: 26px;
  background: #4448CA;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  right: 15%;
  bottom: 8px;
  cursor: pointer;
}

.taoBtn {
  width: 94px;
  height: 35px;
  color: white;
  font-size: 13px;
  line-height: 35px;
  background: #4448CA;
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  right: 20px;
  bottom: 8px;
  cursor: pointer;
}

.tao_type_img {
  height: 30px;
  width: 30px;
  margin-top: 25px;
  margin-left: 40px;
}

.percent_img {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 35px;
  top: 0;
  left: 0;
}

.percent_p {
  color: white;
  position: absolute;
  z-index: 3;
  line-height: 0;
  font-size: 14px;
  top: -2px;
  left: 3px;
}

.tao_type_title {
  line-height: 43px;
  font-weight: 400;
  font-size: 18px;
  margin-left: 10px;
  color: #1D2087;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tao_type_tip {
  margin-left: 25px;
  font-weight: bold;
  font-size: 15px;
  color: #4D71FF;
  line-height: 50px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.tao_type_tip1 {
  width: 100%;
  font-size: 15px;
  line-height: 0;
  text-align: center;
  color: black;
}

.tao_type_money1 {
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}

.tao_type_money {
  font-size: 18px;
  position: absolute;
  right: 130px;
  top: 8px;
}

.percent {
  position: absolute;
  top: 18px;
  left: 0;
  width: 68px;
  height: 30px;
  line-height: 30px;
  color: white;
  text-align: center;
  font-size: 15px;
  background: linear-gradient(91deg, #80BBFF 0%, #5989FA 100%);
  border-radius: 12px 0px 12px 0px;
}

.tao_type {
  position: relative;
  display: flex;
  padding: 0;
  width: 88%;
  line-height: 3vh;
  margin-top: 21px;
  height: 79px;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #6598FF;
  border-radius: 8px;
  /*cursor: pointer;*/
}

.tao_type1 {
  position: relative;
  padding: 0;
  width: 80%;
  line-height: 3vh;
  margin-top: 21px;
  height: 233px;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #6598FF;
  border-radius: 8px;
  /*cursor: pointer;*/
}

.tao_type2_click {
  border: 2px solid #d36830;
}

.vip_font {
  position: absolute;
  right: 33.5%;
  top: -12px;
  z-index: 3;
  font-weight: bold;
  font-size: 18px;
  background: linear-gradient(to right, #e67a33, #a03823);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.taoType2_money {
  position: absolute;
  top: 25px;
  font-size: 22px;
  width: 100%;
  text-align: center;
  color: #c03c3d;
  font-weight: bold;
}

.taoType2_num {
  position: absolute;
  top: 70px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  color: #4a0304;
  font-weight: bold;
}

.taoType2_tip {
  position: absolute;
  top: 110px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #4a0304;
}

.tao_type_click {
  background-color: #eff2fe
}

.tao_type2 {
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  width: 140px;
  margin-left: 20px;
  /*margin-right: 10px;*/
  height: 152px;
  background: linear-gradient(180deg, #F7D3A3 0%, #FEE7D1 100%);
  border-radius: 9px 9px 9px 9px;
}

.old_tip {
  width: 88%;
  height: 15vh;
  min-height: 100px;
  margin-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 6px 12px 0px rgba(194, 194, 194, 0.25);
  border-radius: 0px 0px 0px 0px;
  box-sizing: border-box;
  border: 1px solid #CED2D9;
}

.old_tao {
  line-height: 15px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 88%;
  height: 50px;
  border: 1px solid #CED2D9;
  margin-top: 10px;
}

.smallItem_right {
  box-sizing: border-box;
  text-align: center;
  padding: 30px;
  background: linear-gradient(180deg, #E3EAFC 0%, #FFFFFF 25%, #FFFFFF 100%);
  width: 30%;
  margin-left: 0.7%;
  border-radius: 12px;
}

.smallItem_right_p {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  margin-top: 35px;
  color: #3669CC;
  line-height: 24px;
}

.right_line {
  width: 100%;
  height: 0px;
  border: 1px solid;
  border-image: linear-gradient(90deg, rgba(244, 244, 244, 1), rgba(101, 152, 255, 1), rgba(240, 240, 240, 1)) 1 1;
  margin-top: 8.8vh;
}

.smallTitleP {
  font-size: 16px;
  color: #4B0303;
  line-height: 0;
}

.smallTitleImg {
  height: 20px;
  width: auto;
  margin-top: 8px;
}

.flex {
  display: flex;
}

.smallItem {
  width: 100%;
  height: 100%;
  display: flex;
  /*background-color: black;*/
}

.smallItem2 {
  width: 88%;
  margin-left: 7%;
  height: 87%;
  margin-top: 4%;
  /*background-color: black;*/
}

.smallItem_1 {
  box-sizing: border-box;
  border-radius: 12px;
  width: 20%;
  position: relative;
  margin-right: 10px;
  text-align: center;
  background: linear-gradient(180deg, #E3EAFC 0%, #FFFFFF 25%, #FFFFFF 100%);
}
.smallItem_1_img1{
  position: absolute;
  width: 80%;
  min-width: 190px;
  left: 10px;
  height: auto;
}.smallItem_1_img2{
  width: 60%;
  height: auto;
  margin-top: 20px;
}
.smallItem_1_p1{
  margin-top: 220px;
  font-size: 22px;
  color: #2652ba;
  font-weight: bolder;
}
.smallItem_1_p2{
  width: 75%;
  text-align: left;
  margin-left: 12.5%;
  font-size: 16px;
  color: #889ace;
  margin-top: 20px;
}
.smallItem_1_p3{
  line-height: 28px;
  margin: 20px 0 0;
  font-weight: bolder;
}
.smallItem_1_p4{
  line-height: 28px;
  margin: 0;
  font-weight: bolder;
}
.smallItem_left {
  box-sizing: border-box;
  padding-left: 8%;
  padding-top: 3%;
  border-radius: 12px;
  width: 60%;
  background: linear-gradient(180deg, #E3EAFC 0%, #FFFFFF 25%, #FFFFFF 100%);
}

.current1 {
  margin-top: 60px;
  width: 80vw;
  min-width: 1100px;
  margin-left: 10vw;
  height: 75vh;
  min-height: 650px;
  box-shadow: 1px 1px 10px 5px rgba(216, 227, 255, 0.3);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #D1E1FF;
}

.current2 {
  margin-top: 60px;
  width: 70%;
  min-width: 1000px;
  margin-left: 16vw;
  height: 80vh;
  min-height: 700px;
  background: white;
  box-shadow: 1px 1px 10px 5px rgba(216, 227, 255, 0.3);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #D1E1FF;
}

.tabItem {
  width: 100px;
  text-align: center;
  height: 35px;
  line-height: 35px;
  margin-top: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.clickBtn {
  color: white;
  background: #558EFF;
  border-radius: 12px 12px 12px 12px;
}

.tabList {
  display: flex;
  float: left;
  padding-left: 20%;
}

.main {
  min-width: 1000px;
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}
.dateSelect{
  display: flex;
  height: 35px;
  position: relative;
}
.el-icon-date{
  font-size: 20px;
  color: gray;
  left: 20px;
  top: 20px;
  position: absolute;
}
.span{
  font-size: 15px;
  color: grey;
  left: 43px;
  top: 19px;
  position: absolute;
}
.el-select{
  position: absolute;
  left: 85px;
  top: 10px;
}
</style>