// 卡片管理模块
import request from '@/utils/request'

// 获取
export function getRecordList(params) {
  return request({
    url: 'http://106.15.76.183:8081/pf/record/getRecordList',
    method: 'get',
    params
  })
}
// 站点
export function getSite() {
  return request({
    url: '/site/list',
    method: 'get',
  })
}
// 登录
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'POST',
    data
  })
}// 文本编辑保存
export function save_content(data) {
  return request({
    url: '/api/analyze/save_content',
    method: 'POST',
    data
  })
}// 文本导出
export function content_export(log_id) {
  return request({
    url: '/api/analyze/content_export?log_id='+log_id,
    method: 'GET',
  })
}// 登录
export function send(data) {
  return request({
    url: '/api/sms/send',
    method: 'POST',
    data
  })
}// 换绑验证
export function check_send(data) {
  return request({
    url: '/api/sms/check',
    method: 'POST',
    data
  })
}// 换绑验证
export function changemobile(data) {
  return request({
    url: '/api/user/changemobile',
    method: 'POST',
    data
  })
}// 获得扫码登录二维码
export function login_qrcode() {
  return request({
    url: '/api/wechat/login_qrcode',
    method: 'GET'
  })
}// 获得绑定二维码
export function bind_qrcode() {
  return request({
    url: '/api/wechat/bind_qrcode',
    method: 'GET'
  })
}
// 获得绑定状态
export function bind_status() {
  return request({
    url: '/api/wechat/bind_status',
    method: 'GET'
  })
}
// 获得扫码状态
export function login_status(data) {
  return request({
    url: '/api/wechat/login_status?uuid='+data.uuid,
    method: 'GET'
  })
}// 登录
export function analyzeconfirm(data) {
  return request({
    url: '/api/analyze/confirm',
    method: 'POST',
    data
  })
}// 可用套餐
export function available_packages(data) {
  return request({
    url: '/api/usermember/available_packages',
    method: 'POST',
    data
  })
}// 用户下单
export function user_order(data) {
  return request({
    url: '/api/usermember/order',
    method: 'POST',
    data
  })
}// 获得微信支付扫码二维码
export function order_pay(data) {
  return request({
    url: '/api/usermember/order_pay',
    method: 'POST',
    data
  })
}// 修改用户标签
export function set_user_flag(data) {
  return request({
    url: '/api/user/set_user_flag',
    method: 'POST',
    data
  })
}// 获得微信支付扫码二维码
export function reduceUserWordCount(data) {
  return request({
    url: '/api/user/reduceUserWordCount',
    method: 'POST',
    data
  })
}// 获得微信支付扫码二维码
export function order_pay_result(data) {
  return request({
    url: '/api/usermember/order_pay_result?payment_record_id='+data.payment_record_id,
    method: 'GET'
  })
}// 套餐升级
export function order_upgrade(data) {
  return request({
    url: '/api/usermember/order_upgrade',
    method: 'POST',
    data
  })
}// 消费记录
export function payment_record(params) {
  return request({
    url: '/api/usermember/payment_record',
    method: 'GET',
    params
  })
}// 修改头像
export function changeavatar(data) {
  return request({
    url: '/api/user/changeavatar',
    method: 'POST',
    data
  })
}// 在线检测
export function quick(data) {
  return request({
    url: '/api/analyze/quick',
    method: 'POST',
    data
  })
}// 修改密码
export function changepwd(data) {
  return request({
    url: '/api/user/changepwd',
    method: 'POST',
    data
  })
}// 修改昵称
export function changenickname(data) {
  return request({
    url: '/api/user/changenickname',
    method: 'POST',
    data
  })
}// 手机号码登录
export function mobilelogin(data) {
  return request({
    url: '/api/user/mobilelogin',
    method: 'POST',
    data
  })
}// 获得会员状态
export function order_status(data) {
  return request({
    url: '/api/usermember/order_status',
    method: 'POST',
    data
  })
}// 解除微信绑定
export function unbind(data) {
  return request({
    url: '/api/wechat/unbind',
    method: 'POST',
    data
  })
}// 登录
export function analyzelist(params) {
  return request({
    url: '/api/analyze/list',
    method: 'GET',
    params
  })
}// 检测结果

export function analyzeDetail(id) {
  return request({
    url: '/api/analyze/detail?id='+id,
    method: 'GET',
  })
}
// 检测结果
export function get_content(id) {
  return request({
    url: '/api/analyze/get_content?log_id='+id,
    method: 'GET',
  })
}
// 获得用户信息
export function user_info() {
  return request({
    url: '/api/user/user_info',
    method: 'get',
  })
}// 获取积分使用记录
export function usermember_use(params) {
  return request({
    url: '/api/usermember/usage',
    method: 'get',
    params
  })
}
// 数据更新时间
export function getSchedule() {
  return request({
    url: 'product/schedule',
    method: 'get',
  })
}
// 导出平台list
export function getSiteList() {
  return request({
    url: 'product/getSiteList',
    method: 'get',
  })
}
// 导出产品库存接口
export function postExportProductStock(data) {
  return request({
    url: 'http://106.15.76.183:8081/pf/record/getRecordList',
    method: 'POST',
    responseType: "arraybuffer",
    data
  })
}