import Vue from 'vue'
import App from './App.vue'

import http from './http'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
// 引入时间格式化组件库
import moment from 'moment'
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
Vue.use(ElementUI, { locale })
// 弹框按钮
import {
  Message
} from 'element-ui';
Vue.prototype.$Message = Message
Vue.filter('formatDate', function(value) {
  const result = moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') // 转换时间格式
  return result // 返回出去 否则不会生效
});
Vue.filter('twoDecimals', function(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(2);
});
Vue.filter('zeroDecimals', function(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(0);
});Vue.filter('oneDecimals', function(value) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(1);
});
Vue.directive('demo', {
  twoWay: true,
  bind: function () {
    this.handler = function () {
      this.set(this.el.innerHTML)
    }.bind(this)
    this.el.addEventListener('input', this.handler)
  },
  update: function (newValue, oldValue) {
    this.el.innerHTML = newValue || ''
  },
  unbind: function () {
    this.el.removeEventListener('input', this.handler)
  }
})
import router from './router'
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = http




new Vue({
  router,
  render: h => h(App),
}).$mount('#app')