<template>
  <div style="background: linear-gradient(180deg, #ECF5FF 0%, #F8FBFF 100%);min-height: 100vh;min-width: 100vw;">
    <div class="main">
      <div class="current2">
        <div class="smallItem2" style="position: relative;height: 10vh;width: 100%;margin: 0;">
          <div class="dateSelect">
            <i class="el-icon-date"></i>
            <span class="span">日期：</span>
            <el-select class="el-select" v-model="params" placeholder="请选择" @change="dateChange">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="custom-scrollbar" style="height: 85vh;overflow-y: scroll;margin-top: 30px">
            <div :key="index" v-for="(item,index) in booklist2"
                 style="height: 90px;width: 100%;border-bottom: #E7E7E7 1px solid;font-size: 15px;background-color: white;box-shadow: 0 8px 8px 0 rgba(216,214,214,0.25);margin-top: 10px;border-radius: 8px;line-height: 30px;padding-left: 15px;padding-right: 15px;box-sizing: border-box">
              <div STYLE="display: flex;justify-content: space-between">
                <div class="slh" style="color: #B39354">
                  {{ item.order.level === 1 ? '初级' : item.order.level === 2 ? "中级" : item.order.level === 3 ? "高级" : item.order.level === 4 ? "旗舰版" : "" }}
                  {{
                    item.order.order_time_len === 'month' ? '—月卡' : item.order.order_time_len === 'quarter' ? '—季卡' : item.order.order_time_len === 'year' ? '—年卡' : ''
                  }}{{ item.order.type === 'member' ? '—购买会员' : '购买增量包' }}
                </div>
              </div>
              <div STYLE="display: flex;justify-content: space-between">
                <div class="slh" style="overflow: hidden;display: block;white-space: nowrap;text-overflow: ellipsis;">
                  订单编号:{{ item.orderno }}
                </div>
              </div>
              <div STYLE="display: flex;justify-content: space-between">
                <div class="slh" style="color: #1D9461">充值金额:{{ item.actual_paid_amount }}</div>
                <div class="slh">{{ item.payment_time | formatDate }}</div>
              </div>
            </div>
            <el-empty v-if="booklist2.length===0" description="暂无充值记录" style="margin-top: 10vh"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  changeavatar, changenickname, changepwd, order_upgrade, payment_record, unbind, usermember_use
} from "@/api/1";
import QRCode from 'qrcodejs2';
import axios from "axios";

export default {
  // eslint-disable-next-line vue/no-unused-components
  data() {
    return {
      params: 1,
      options: [
        {
          value: 1,
          label: '近一个月'
        }, {
          value: 2,
          label: '近三个月'
        }, {
          value: 3,
          label: '近一年'
        }, {
          value: 4,
          label: '全部'
        }
      ],
      booklist2: [],
      payment_record_data: {
        page: 1,
        limit: 500
      },
    }
  },
  created() {
    // console.log(this.isMobile())
    if (this.isMobile()) {
      this.$router.push("/czjl")
    } else {
      this.$router.push("/records")
    }
    const that = this
    setTimeout(() => {
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        that.get_payment_record()
      } else {
        that.$router.push("/")
      }
    }, 500);
  },

  methods: {
    //该方法用于判断是否进入手机端
    isMobile() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },

    dateChange(e) {
      // console.log(e)
      this.get_payment_record()
    },
    get_payment_record() {
      // console.log(this.params)
      this.payment_record_data.end_at = new Date().getTime();
      if (this.params === 1) {
        this.payment_record_data.start_at = moment().subtract(1, 'months')._d.getTime()
        this.payment_record_data.start_at = parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params === 2) {
        this.payment_record_data.start_at = moment().subtract(2, 'months')._d.getTime()
        this.payment_record_data.start_at = parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params === 3) {
        this.payment_record_data.start_at = moment().subtract(12, 'months')._d.getTime()
        this.payment_record_data.start_at = parseInt(this.payment_record_data.start_at / 1000) + '';
      }
      if (this.params === 4) {
        this.payment_record_data.start_at = -1
      }
      payment_record(this.payment_record_data).then(res => {
        // console.log(res.data.data)
        this.moneyMoney = res.data.data.total
        this.booklist2 = res.data.data.rows
      });
    },
  },
};
</script>

<style scoped>

/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* 针对IE */
.custom-scrollbar {
  -ms-overflow-style: thin;
}

/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}


.smallItem2 {
  width: 88%;
  margin-left: 7%;
  height: 87%;
  margin-top: 4%;
  /*background-color: black;*/
}

.current2 {
  width: 90vw;
  margin-left: 5vw;
  min-height: 90vh;
  /*background: white;*/
  /*box-shadow: 1px 1px 10px 5px rgba(216, 227, 255, 0.3);*/
  border-radius: 12px 12px 12px 12px;
  /*border: 1px solid #D1E1FF;*/
}

.main {
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}
.slh{
  text-align: left;
  font-size: 14px;
}
.dateSelect {
  display: flex;
  height: 35px;
  position: relative;
}

.el-icon-date {
  font-size: 20px;
  color: gray;
  left: 20px;
  top: 20px;
  position: absolute;
}

.span {
  font-size: 15px;
  color: grey;
  left: 43px;
  top: 19px;
  position: absolute;
}

.el-select {
  position: absolute;
  left: 85px;
  top: 10px;
}
</style>