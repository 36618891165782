<template>
  <div
      style="background: linear-gradient( 180deg, #D5EBFF 0%, #F0F7FF 15%, #FAFCFF 22%, #FAFCFF 100%);position: relative;width: 100vw;height: 100vh">
    <div class="main">
      <p class="main-title">文件<span style="color: #4b6ffd">导入</span></p>
      <!--      <p>点击或拖拽到下方白色区域上传文件</p>-->
      <input type="file" @change="handleFileUpload" style="display: none" ref="fileInput">

      <div class="center" @dragenter="dragEnter" @click="selectFile" v-if="!showList"
           @dragleave="dragLeave"
           @dragover="dragOver"
           @drop="dropFile">
        <div class="loadingBG" v-if="isLoading">
          <div class="loadingbox">
            <littie></littie>
            <p class="message">{{ message }}</p>
          </div>
        </div>
        <div class="center_item dropzone" v-if="!isLoading">

          <p>点击此处上传文件</p>
          <div class="typelist">
            <div class="typelist-item">
              <img class="typelist-item-img" src="../../assets/word.png" alt="">
              <p class="typelist-item-p">doc/docx</p>
            </div>
            <div class="typelist-item">
              <img class="typelist-item-img" src="../../assets/tet.png" alt="">
              <p class="typelist-item-p">TXT</p>
            </div>
            <div class="typelist-item">
              <img class="typelist-item-img" src="../../assets/zip.png" alt="">
              <p class="typelist-item-p">zip压缩包</p>
            </div>
          </div>
          <p style="font-size: 13px;color: #8c939d">仅支持以上格式</p>
        </div>
      </div>
      <div class="container" v-if="showList">
        <!--        <div style="display: flex;float: left;padding: 10px;height: 30px">-->
        <!--&lt;!&ndash;          <img style="width: 20px;height: 20px;margin-top: 5px" src="../../assets/alert.png" alt="">&ndash;&gt;-->
        <!--&lt;!&ndash;          <p style="line-height: 0px;margin-left: 10px;font-size: 15px;color: #4D71FF">请稍作等待，检测将在2-5分钟后出结果</p>&ndash;&gt;-->
        <!--        </div>-->
        <div class="custom-scrollbar"
             style="height: 58vh;overflow-y: scroll;width: 100%;padding: 15px;box-sizing: border-box">
          <div v-for="(item,index1) in commitFormList">
            <!--            -->
            <div v-for="(item1,index2) in item.detail" style="height: 80px;width: 100%;border-bottom: #E7E7E7 1px solid;font-size: 15px">
              <div style="display: flex;">
                <div class="slh" style="width: 50%;color: #4D71FF">{{ item1.file_name }}</div>
                <div class="slh slh_small" style="width: 50%"><span
                    style="color: black;margin-right: 5px">字数:</span>{{ item1.credits }}</div>
              </div>
              <div style="display: flex;">
                <div class="slh" style="width: 50%;color: #1D9461"><span style="color: black;margin-right: 5px">消耗积分:</span>{{ item1.file_tokens }}</div>
                <div class="slh" style="width: 50%;display: flex;">
                  <div class="delBtn" @click="del(index1,index2)">
                    删除
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: end;position: absolute;width: 100%">
          <div class="totalNum">共计:{{ total_num }}文件</div>
          <div class="backBtn" @click="backTo">返回</div>
          <div v-if="submitPower" class="submitBtn" @click="submit">提交</div>
          <div v-else class="submitBtn">提交</div>
        </div>
      </div>

      <!--      <div v-for="file in files" :key="file.name">-->
      <!--        文件名：{{ file.name }}，大小：{{ file.size }}字节-->
      <!--        <button @click="downloadFile(file)">下载</button>-->
      <!--      </div>-->
    </div>
    <p class="alert_tip">
      书镜鉴文所标记的AI痕迹字句，皆由书镜鉴文根据AI常用字句自行甄别得出，无论任何稿子都有一定可能被标出AI痕迹，因此书镜鉴文所标记的AI痕迹字句，只提供参考修改作用，无法用于确切证明文稿是由AI创作得出。</p>
    <TelFeet :click="1"></TelFeet>

  </div>
</template>

<script>
import top from "@/components/Top.vue";
import littie from "@/components/littie.vue";
import axios from 'axios'
import {analyzeconfirm, order_status, reduceUserWordCount} from "@/api/1";
import TelFeet from "@/components/tel_feet.vue";

export default {
  components: {top, littie, TelFeet},
  data() {
    return {
      detailList: [],
      total_num: 0,
      showList: false,
      message: '您的文本正在上传中请耐心等待', // 自定义加载中文本
      MAX_FILE_SIZE: 10485760,
      click: 1,
      files: [],
      zipName: "",
      currentPage: 1,
      dropzoneActive: false,
      isLoading: false,
      submitPower: true,
      commitFormList: []
    };
  },
  created() {
    // console.log(this.isMobile())
    if (this.isMobile()) {
      this.$router.push("/homeTel")
    } else {
      this.$router.push("/home")
    }
  },

  methods: {
    isMobile() {
      const userAgentInfo = navigator.userAgent;
      const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
          return true; // 是移动设备
        }
      }
      return false; // 是PC设备
    },
    get_total_num() {
      let num = 0
      for (let i = 0; i < this.commitFormList.length; i++) {
        for (let j = 0; j < this.commitFormList[i].detail.length; j++) {
          num += 1
        }
      }
      this.total_num = num
    },
    backTo() {
      this.currentPage = 1
      this.isLoading = false
      this.showList = false
      this.files = []
      this.commitFormList = []
    },
    del(index1, index2) {
      if (this.commitFormList.length > 0) {
        this.commitFormList[index1].detail.splice(index2, 1);
        if (this.commitFormList[index1].detail.length === 0) {
          this.commitFormList.splice(index1, 1);
        }
        if (this.commitFormList.length === 0) {
          this.backTo()
        } else {
          this.get_total_num()
        }
      }
    },
    async submit() {
      this.submitPower = false
      const that = this
      let needCredits = 0
      for (let i = 0; i < this.commitFormList.length; i++) {
        for (let j = 0; j < that.commitFormList[i].detail.length; j++) {
          if (that.commitFormList[i].detail[j].credits > 100000) {
            const confirmResult = await that.$confirm("提交失败，" + "提交单个文本字数不可超过10万字", '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              closeOnClickModal: false,
              showClose: false,
              type: 'error'
            }).catch(err => err)
            /*如果用户确认打印confirm,如果用户取消显示cancel*/
            if (confirmResult === 'confirm') {
              return
            }
          }
          needCredits += that.commitFormList[i].detail[j].credits
        }
      }
      order_status().then(async res => {
        if (res.data.data.remain_word_count > needCredits) {
          for (let i = 0; i < this.commitFormList.length; i++) {
            let list = []
            for (let j = 0; j < that.commitFormList[i].detail.length; j++) {
              list.push(that.commitFormList[i].detail[j].file_index)
            }
            analyzeconfirm({attach_id: that.commitFormList[i].attach_id, index: list}).then(res => {
              // console.log(res)
              for (let k = 0; k < that.commitFormList[i].detail.length; k++) {
                reduceUserWordCount({type: 1, tokens: that.commitFormList[i].detail[k].credits}).then(res => {
                  // console.log(res)
                  that.submitPower = true
                })
              }
            });
            await that.$router.push("/category")
          }
        } else {
          that.submitPower = true
          const confirmResult = await that.$confirm('您的积分不足啦，是否前往补充积分?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)
          /*如果用户确认打印confirm,如果用户取消显示cancel*/
          if (confirmResult !== 'confirm') {
            return
          } else {
            await that.$router.push("/records")
          }
        }

      })
    },
    dragEnter(event) {
      event.preventDefault();
      this.dropzoneActive = true;
    },
    /**
     * 处理拖拽区域的拖离事件
     * @param {Event} event - 拖离事件对象
     */
    dragLeave(event) {
      event.preventDefault();
      this.dropzoneActive = false;
    },
    /**
     * 处理拖拽区域的拖放事件
     * @param {Event} event - 拖放事件对象
     */
    dragOver(event) {
      event.preventDefault();
    },
    /**
     * 处理文件的拖放上传
     * @param {Event} event - 拖放事件对象
     */
    dropFile(event) {
      event.preventDefault();
      this.dropzoneActive = false;
      const files = event.dataTransfer.files;
      this.handleFiles(files);
      // console.log(files);
    },
    /**
     * 处理选择文件按钮的上传事件
     * @param {Event} event - 选择文件事件对象
     */
    selectFile() {
      this.$refs.fileInput.click();
    },
    fileLoad(file) {
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.zipName = file.name
        const that = this
        let formData = new FormData()
        formData.append('file', file)
        formData.append("category", "custom")
        if (!file.name.includes(".zip") && !file.name.includes(".txt") && !file.name.includes(".doc") && !file.name.includes(".docx")) {
          this.$message.error('文件格式不正确，请上传zip,文件夹, 文档(.doc/.docx) 或 txt 文件。');
          return;
        }
        this.isLoading = true
        that.commitForm = []
        // 使用Axios进行文件上传
        axios.post('https://sj.yundianai.com/api/common/upload', formData, {
          headers: {
            'Token': JSON.parse(localStorage.getItem("userinfo")).token
          }
        }).then(response => {
          that.isLoading = false
          that.showList = true
          that.commitFormList.push(response.data.data)
          const commitFormArrey = that.commitFormList
          that.commitFormList = commitFormArrey
          that.get_total_num()
          // console.log(that.commitFormList)
        })
            .catch(error => {
              // this.$refs.top.openLogin()
              // console.error(error);
            });
      } else {
        this.$refs.top.openLogin()
      }
    },
    /**
     * 处理上传的文件列表
     * @param {FileList} files - 文件列表
     */
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.files.push(file);
        // 在这里可以执行文件上传的相关操作
        this.fileLoad(file)
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) {
        alert('请选择一个文件');
        return;
      }
      if (file.size > this.MAX_FILE_SIZE) {
        this.$message.error("文件不得超过10M")
      }
      // 这里可以添加上传文件的逻辑
      // console.log(file);
      // 示例：使用FormData发送文件
      this.fileLoad(file)
    },
    /**
     * 下载文件
     * @param {File} file - 文件对象
     */
    downloadFile(file) {
      let formData = new FormData()
      formData.append('file', file)
      formData.append("category", "custom")
      // 使用Axios进行文件上传
      axios.post('https://sj.yundianai.com/api/common/upload', formData, {
        headers: {
          'Token': JSON.parse(localStorage.getItem("userinfo")).token
        }
      }).then(response => {
        // console.log(response.data);
      })
          .catch(error => {
            // console.error(error);
          });
      // const url = URL.createObjectURL(file);
      // const link = document.createElement('a');
      // console.log(url)
      // console.log(link)
      // link.href = url;
      // link.download = file.name;
      // link.click();
      // URL.revokeObjectURL(url);
    },
    // 点击上传
    chooseUploadFile(e) {
      const file = e.target.files.item(0)
      if (!file) return
      if (file.size > this.MAX_FILE_SIZE) {
        this.$message.error("文件不得超过10M")
      }
      if (file.name) {

      }
      this.batchFile = file
      this.fileName = file.name

      // 清空，防止上传后再上传没有反应
      e.target.value = ''
    },
    // 拖拽上传
    fileDragover(e) {
      e.preventDefault()
    },
    fileDrop(e) {
      e.preventDefault()
      const file = e.dataTransfer.files[0] // 获取到第一个上传的文件对象
      // console.log(file)
      // console.log('拖拽释放鼠标时')

      if (!file) return
      if (file.size > this.MAX_FILE_SIZE) {
        return alert('文件大小不能超过10M')
      }

      this.batchFile = file
      this.fileName = file.name
    },
    // 提交
    uploadOk() {
      if (this.batchFile === '') {
        return alert('请选择要上传的文件')
      }
      let data = new FormData()
      data.append('upfile', this.batchFile)
      // ajax
    }
  },
};
</script>

<style scoped>
.slh {
  padding-left: 10px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  line-height: 35px;
}

.slh_small {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #989899;
  font-size: 13px;
  box-sizing: border-box;
  white-space: nowrap;
}

.loadingbox {
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%) translateY(3vh);
  text-align: center;
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  color: #8c939d;
}

@keyframes loadingImg {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1440px);
  }
}

.templateLoading {
  width: 160px;
  height: 160px;
  overflow: hidden;
  pointer-events: none;

.img {
  width: 1440px;
  height: 160px;
  animation: 1800ms steps(9) 0s infinite normal none running loadingImg;
}

}
.delBtn {
  background: #6598FF;
  border-radius: 4px 4px 4px 4px;
  height: 28px;
  width: 18vw;
  text-align: center;
  margin-top: 7px;
  line-height: 28px;
  color: white;
  cursor: pointer;
  margin-left: 12vw;
}

.reBtn {
  background: #7BC8E4;
  margin-left: 10%;
  border-radius: 4px 4px 4px 4px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  margin-top: 13px;
  line-height: 30px;
  color: white;
  cursor: pointer;
}

.backBtn {
  position: absolute;
  right: 25vw;
  background: #D9E6FF;
  margin-left: 25px;
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-top: 13px;
  width: 20vw;
  line-height: 40px;
  color: #6598FF;
  cursor: pointer;
}

.totalNum {
  position: absolute;
  left: 0;
  top: 25px;
  color: grey;
  cursor: pointer;
}

.submitBtn {
  position: absolute;
  right: 0;
  width: 20vw;
  background: #6598FF;
  margin-left: 25px;
  border-radius: 4px 4px 4px 4px;
  height: 40px;
  margin-top: 13px;
  line-height: 40px;
  color: #D9E6FF;
  cursor: pointer;
}

/* 为滚动区域添加一个类名，如 .custom-scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* 水平滚动条的宽度 */
  height: 10px; /* 垂直滚动条的高度 */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条的颜色 */
  border-radius: 5px; /* 滚动条的圆角 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* 滚动条轨道的颜色 */
}

/* 针对Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/*!* 针对IE *!*/
/*.custom-scrollbar {*/
/*  -ms-overflow-style: thin;*/
/*}*/
/deep/ .el-tabs__nav-wrap::after {
  position: static !important;
}

.container {
  position: relative;
  width: 90vw;
  margin-left: 5vw;
  margin-top: 10px;
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 5px rgba(209, 227, 244, 0.3);
  border-radius: 12px 12px 12px 12px;
  /*border: 1px solid #ADC9FF;*/
}

.center {
  padding-top: 50px;
  width: 90vw;
  height: 50vh;
  margin-left: 5vw;
  margin-top: 40px;
  min-height: 300px;
  background: #FFFFFF;
  box-shadow: 1px 1px 10px 5px rgba(191, 218, 244, 0.3);
  border-radius: 12px 12px 12px 12px;
  border: 1px solid #ADC9FF;
  cursor: pointer;
}

.center:hover {

}

.center_item {
  /*background-color: #d7d1d1;*/
  margin-left: 10vw;
  width: 70vw;
  height: 40%;
}

.typelist {
  height: 70%;
  display: flex;
  justify-content: space-around;
}

.typelist-item {
  width: 15%;
  min-width: 80px;
  height: auto;
  padding-top: 30px;
  /*background-color: #d7d1d1;*/
}

.typelist-item-p {
  text-align: center;
  font-size: 15px;
}

.typelist-item-img {
  width: 50%;
  min-width: 40px;
}

.main-title {
  line-height: 70px;
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: bolder;
  color: rgb(50, 50, 96);
}

.main {
  position: relative;
  height: 91vh;
  padding-top: 10px;
  text-align: center;
  /*min-height: 100vh;*/
  width: 100%;
}

.alert_tip {
  left: 5vw;
  width: 90vw;
  text-align: center;
  position: absolute;
  top: 78vh;
  font-size: 13px;
  color: darkgrey;

}
</style>