<template>
  <div style="background: linear-gradient( 180deg, #D5EBFF 0%, #F0F7FF 15%, #F5F5F5 22%, #FFFFFF 100%);width: 100vw;height: 100vh">
<!--    <img src="../../assets/helpCenter.png" class="helpCenter" alt="">-->
    <div class="dialog-box">
      <img :src="nowUser.avatar" class="dialog-avatar" alt="">
      <p class="dialog-nickname">{{ nowUser.nickname }}</p>
      <p class="dialog-id">ID:{{ nowUser.id }}</p>
      <div class="dialog-remain_word_count">
        <p>积分</p>
        <p>{{ now_vip_status.remain_word_count }}</p>
      </div>
      <div class="vip-box vip0-bg" @click="beVip()" v-if="now_vip_status.top_level<=0">
        点击开通会员
      </div>
      <div class="vip-box vip1-bg" v-if="now_vip_status.top_level===1">
        初级
      </div>
      <div class="vip-box vip2-bg" v-if="now_vip_status.top_level===2">
        中级
      </div>
      <div class="vip-box vip3-bg" v-if="now_vip_status.top_level===3">
        高级
      </div>
      <div class="vip-box vip4-bg" v-if="now_vip_status.top_level===4">
        旗舰版
      </div>
      <img class="win-png" src="../../assets/win.png" alt="" v-if="now_vip_status.top_level>0">
      <img v-if="now_vip_status.top_level===1" class="tip-vip" src="../../assets/tip-vip1.png" alt="">
      <img v-if="now_vip_status.top_level===2" class="tip-vip" src="../../assets/tip-vip2.png" alt="">
      <img v-if="now_vip_status.top_level===3" class="tip-vip" src="../../assets/tip-vip3.png" alt="">
      <img v-if="now_vip_status.top_level===4" class="tip-vip" src="../../assets/tip-vip4.png" alt="">
      <p class="now_level" v-if="now_vip_status.top_level>0">当前等级</p>
      <p class="end_date_at" v-if="now_vip_status.top_level>0">会员有效期至{{now_vip_status.end_at|formatDate}}
      </p>
      <div class="myServe">
        <p class="myServe_p">我的服务</p>
        <div style="display: flex;height: 70px">
<!--          <div class="myServe_item" @click="beVip(1)">-->
<!--            <img class="myServe_item_img" src="../../assets/tel_person/vip.png" alt="">-->
<!--            <p class="myServe_item_p">会员充值</p>-->
<!--          </div>-->
<!--          <div class="myServe_item" >-->
<!--            <img class="myServe_item_img" src="../../assets/tel_person/info.png" alt="">-->
<!--            <p class="myServe_item_p">个人资料</p>-->
<!--          </div>-->
          <div class="myServe_item" @click="beVip(2)" >
            <img class="myServe_item_img" src="../../assets/tel_person/money.png" alt="">
            <p class="myServe_item_p">充值记录</p>
          </div>
          <div class="myServe_item" @click="beVip(3)" >
            <img class="myServe_item_img" src="../../assets/tel_person/use_money.png" alt="">
            <p class="myServe_item_p">消费记录</p>
          </div>
        </div>
      </div>
      <div class="back_log" @click="logOut">
        退出登录
        <i style="font-size: 25px;color: #8c939d;line-height: 55px" class="el-icon-arrow-right"></i>
      </div>
      <TelFeet :click="3"></TelFeet>
    </div>
  </div>
</template>

<script>
import {order_status} from "@/api/1";
import TelFeet from "@/components/tel_feet.vue";
export default {
  components: {TelFeet},
  data() {
    return {
      now_vip_status:{},
      nowUser:'',
    };
  },
  created() {
    this.reLoad()
  },

  methods: {
    beVip(index){
      if (Number(index)===1){
        this.$router.push("/beVip")
      }
      if (Number(index)===2){
        this.$router.push("/czjl")
      }
      if (Number(index)===3){
        this.$router.push("/xfjl")
      }
    },
    reLoad(){
      const that =this
      if (localStorage.getItem("userinfo") !== null && localStorage.getItem("userinfo") !== 'null' && localStorage.getItem("userinfo") !== '' && localStorage.getItem("userinfo") !== undefined && localStorage.getItem("userinfo") !== 'undefined') {
        this.nowUser=JSON.parse(localStorage.getItem("userinfo"))
        order_status().then(res => {
          // console.log(res.data.data)
          that.now_vip_status = res.data.data
        })
      }else {
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
    async logOut() {
      const that =this
      const confirmResult = await this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      /*如果用户确认打印confirm,如果用户取消显示cancel*/
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消退出登录!')
      } else {
        this.nowUser = null
        localStorage.setItem("userinfo", null)
        this.$message.success('您已退出登录!')
        setTimeout(function() {
          that.$router.push("/first")
        }, 600);
      }
    },
  },
};
</script>


<style scoped>
.myServe_item{
  width: 25%;
  height: 100%;
  text-align: center;
}
.myServe_p{
  margin-bottom: 0;
  margin-top: 0;
}
.myServe_item_img{
  width: 50px;
  height: 50px;
}
.myServe_item_p{
  margin: 0;
  line-height: 0px;
  font-size: 14px;
}
.helpCenter{
  cursor: pointer;
  position: absolute;
  height: 10vw;
  width: 10vw;
  top: 20px;
  left: 83vw;
}
.end_date_at{
  position: absolute;
  font-size: 13px;
  top: 215px;
  line-height: 0;
  left: 4vw;
  color: #5F72BE;
}
.myServe{
  cursor: pointer;
  height: 160px;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0 rgba(234,234,234,0.25);
  /*border-top: #dcddde 1px solid;*/
  line-height: 55px;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  left: 2.5vw;
  top: 260px;
  width: 95vw;
}
.back_log{
  cursor: pointer;
  height: 55px;
  background-color: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px 0 rgba(234,234,234,0.25);
  /*border-top: #dcddde 1px solid;*/
  line-height: 55px;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  left: 2.5vw;
  /*top: 260px;*/
  top: 430px;
  width: 95vw;
}
.dialog-avatar{
  position: absolute;
  top: 30px;
  left: 50px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.dialog-nickname{
  line-height: 0;
  position: absolute;
  top: 30px;
  left: 135px;
}
.dialog-id{
  line-height: 0;
  position: absolute;
  top: 60px;
  left: 135px;
}
.vip-box{
  width: 95vw;
  position: absolute;
  left: 2.5vw;
  height: 115px;
  background-color: #d9deeb;
  top: 130px;
  border-radius: 12px;
}
.vip0-bg{
  text-align: center;
  cursor: pointer;
  line-height: 115px;
  font-size: 22px;
  color: #909cc4;
}
.vip1-bg{
  background: linear-gradient( 90deg, #C4E2F5 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.win-png{
  position: absolute;
  top: 135px;
  width: 80px;
  right: 60px;
}
.tip-vip{
  position: absolute;
  top: 130px;
  width: 80px;
  text-align: center;
  left: 2.5vw;
}
.now_level{
  position: absolute;
  top: 127px;
  width: 70px;
  left: 15px;
  color: #fff;
  font-size: 15px;
  line-height: 5px;
}
.vip2-bg {
  background: linear-gradient(90deg, #d2dfec 0%, #c7cde8 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip3-bg{
  background: linear-gradient( 90deg, #b3d2ed 0%, #a7baf4 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.vip4-bg{
  background: linear-gradient( 90deg, #a0abe4 0%, #D8EEFF 100%);
  border-radius: 12px 12px 12px 12px;
  line-height: 115px;
  font-size: 25px;
  color: #0f2991;
  font-weight: bolder;
  text-align: center;
}
.dialog-remain_word_count{
  position: absolute;
  top: 30px;
  width: 20vw;
  height: 60px;
  right: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 8px;
  color: #971717;
  background: linear-gradient( 180deg, #FFCEA7 0%, #FFE2A4 100%);
  border-radius: 8px;
}
.dialog-box{
  position: absolute;
  z-index: 10;
  right: 0;
  top: 50px;
  width: 100vw;
  height: 70vh;
}

.dialog-box2 .p1 {
  height: 30px;
  font-size: 18px;
  color: #1D9461;
  /*font-weight: bold;*/
  line-height: 30px;
}
.dialog-box2 .p2 {
  width: 80%;
  margin-left: 10%;
  height: 33px;
  line-height: 33px;
  background: linear-gradient( 90deg, #75F09C 0%, #8AFAF3 100%);
  border-radius: 12px 12px 12px 12px;
}

</style>